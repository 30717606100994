/* eslint-disable max-lines */
import { ToastType } from 'components/atoms/Toast';
import { CALCULATOR_STORAGE_KEY, IS_IFRAME_DONE_STORAGE_KEY } from 'consts/storage.const';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { IGovMapAddress, ISavedProjectAddress, IMapContext, ISearchByAddressOption, IIframeData } from 'models/MapData';
import { enqueueSnackbar } from 'notistack';
import { PATH } from 'paths';
import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GOV_MAP_TOKEN } from 'utils/envUtils';

const initialState: IMapContext = {
    showMapSection: false,
    isPanelsExist: undefined,
    setIsPanelsExist: (value: boolean | undefined) => {},
    setShowMapSection: (value: boolean) => {},
    footerButtonData: { text: '', onClick: () => {} },
    setFooterButtonData: (data: { text: string; onClick: () => void }) => {},
    searchByPlot: (value: { block: string; plot: string }) => {},
    getIframeDataFunction: () => {},
    setGetIframeDataFunction: () => {},
    searchByAddress: (address: IGovMapAddress) => {},
    calculator: null,
    setCalculator: (value: ISavedProjectAddress | null) => {},
    fetchAddressList: (value: any) => {},
    mapLoading: true,
    iFrameData: null,
    setIFrameData: (value: IIframeData | null) => {},
};

export const MapContext = createContext<IMapContext>(initialState);

interface IProps {
    children: ReactNode;
}

const windowRef = window as any;
const govmap = windowRef.govmap;

const MapProvider: FC<IProps> = ({ children }) => {
    const path = useLocation().pathname;

    const [calculator, setCalculator] = useLocalStorage<ISavedProjectAddress | null>(CALCULATOR_STORAGE_KEY, null);
    const [iFrameData, setIFrameData] = useLocalStorage<IIframeData | null>(IS_IFRAME_DONE_STORAGE_KEY, null);
    const [showMapSection, setShowMapSection] = useState(false);
    const [footerButtonData, setFooterButtonData] = useState({ text: '', onClick: () => {} });
    const [mapLoading, setMapLoading] = useState(true);
    const [isPanelsExist, setIsPanelsExist] = useState<boolean | undefined>(undefined);
    const [getIframeDataFunction, setGetIframeDataFunction] = useState(() => () => {});
    const getAddressPath = `/${PATH.CALCULATOR}/${PATH.ADDRESS}/${PATH.GET_ADDRESS}`;

    const createMap = useCallback(() => {
        govmap?.createMap('map', {
            token: GOV_MAP_TOKEN,
            background: '2',
            // layers: ['GASSTATIONS', 'PARCEL_HOKS', 'KSHTANN_ASSETS', 'bus_stops', 'PARCEL_ALL'],
            // layers: ['yeshuvim', 'LOCALITY_210410', 'LOCALITY', 'MUNICIPALY'],
            layersMode: 4,
            zoomButtons: true,
            identifyOnClick: true,
            isEmbeddedToggle: false,
            onLoad: () => {
                setMapLoading(false);
            },
            onError: (e: any) => {
                enqueueSnackbar(e || 'Error', {
                    variant: 'toast',
                    type: ToastType.ERROR,
                });
            },
        });
        onMapClick();
        // eslint-disable-next-line
    }, []);

    const onMapClick = () => {
        govmap?.onEvent(govmap?.events.CLICK).progress((e: any) => {
            zoomToXY(e.mapPoint.x, e.mapPoint.y);
            const calculatorLocalStorage = localStorage.getItem(CALCULATOR_STORAGE_KEY);
            if (calculatorLocalStorage) {
                const calculator = JSON.parse(calculatorLocalStorage);
                setCalculator({
                    ...calculator,
                    address: calculator?.address,
                    itemEast: e.mapPoint.x,
                    itemNorth: e.mapPoint.y,
                    step: 1,
                    plot: '',
                });
            } else {
                setCalculator({
                    propertyType: calculator?.propertyType || null,
                    address: '',
                    itemEast: e.mapPoint.x,
                    itemNorth: e.mapPoint.y,
                    step: 1,
                    plot: '',
                });
            }
        });
    };

    const fetchAddressList = useCallback(async (debouncedInputValue: any): Promise<ISearchByAddressOption | null | undefined> => {
        try {
            const response = await govmap?.geocode({ keyword: debouncedInputValue, type: govmap.geocodeType.FullResult });
            return response?.data?.length ? response?.data : null;
        } catch (e: any) {
            enqueueSnackbar(e?.statusText || 'Error', {
                variant: 'toast',
                type: ToastType.ERROR,
            });
        }
    }, []);

    const searchByPlot = useCallback(
        async (value: { block: string; plot: string }) => {
            try {
                const response = await govmap?.geocode({
                    keyword: `גוש ${value.block} חלקה ${value.plot}`,
                    type: govmap.geocodeType.FullResult,
                });
                const data = response?.data?.[0];
                if (data) {
                    setCalculator({
                        ...calculator,
                        plot: data.ResultLable,
                        itemEast: data.X,
                        itemNorth: data.Y,
                        step: 1,
                        address: '',
                        lot: value.block,
                        parsel: value.plot,
                    });
                    zoomToXY(data.X, data.Y);
                } else {
                    setCalculator({ noResults: true });
                }
            } catch (e: any) {
                enqueueSnackbar(e?.statusText || 'Error', {
                    variant: 'toast',
                    type: ToastType.ERROR,
                });
                setCalculator(null);
            }
        },
        [calculator, setCalculator]
    );

    const searchByAddress = useCallback(
        (address: IGovMapAddress) => {
            setCalculator({
                ...calculator,
                address: address.ResultLable,
                itemEast: address.X,
                itemNorth: address.Y,
                step: 1,
                plot: '',
                lot: '',
                parsel: '',
            });
            zoomToXY(address.X, address.Y);
        },
        [calculator, setCalculator]
    );

    const zoomToXY = (x: number, y: number) => {
        govmap?.zoomToXY({ x, y, level: 10, marker: true });
    };

    useEffect(() => {
        if (calculator === null) {
            setMapLoading(true);
            setIFrameData(null);
            createMap();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculator, createMap]);

    useEffect(() => {
        if (path !== getAddressPath) {
            setShowMapSection(false);
        } else {
            setShowMapSection(true);
        }
    }, [getAddressPath, path]);

    const values = useMemo(
        () => ({
            showMapSection,
            setShowMapSection,
            footerButtonData,
            setFooterButtonData,
            searchByPlot,
            getIframeDataFunction,
            setGetIframeDataFunction,
            searchByAddress,
            setCalculator,
            calculator,
            fetchAddressList,
            mapLoading,
            iFrameData,
            setIFrameData,
            isPanelsExist,
            setIsPanelsExist,
        }),
        [
            calculator,
            fetchAddressList,
            footerButtonData,
            getIframeDataFunction,
            mapLoading,
            searchByAddress,
            searchByPlot,
            setCalculator,
            showMapSection,
            iFrameData,
            setIFrameData,
            isPanelsExist,
            setIsPanelsExist,
        ]
    );
    return <MapContext.Provider value={values}>{children}</MapContext.Provider>;
};;

export default MapProvider;
