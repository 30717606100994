import { useGetAgentProjects, useGetClientProjects } from 'api/queries/myOffers';
import MyProjects from 'components/organisms/myOffers/MyProjects';
import SortQuotations from 'components/organisms/myOffers/SortQuotations';
import Title from 'components/organisms/myOffers/Title';
import { CALCULATOR_STORAGE_KEY } from 'consts/storage.const';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { EProjectStatusType } from 'models/api/common';
import { IMyOffer } from 'models/myOffers';
import { PATH } from 'paths';
import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ISavedProjectAddress } from 'models/MapData';
interface IProps {
    name: string;
    isClient?: boolean;
}

const getPath = (offerStatus: EProjectStatusType) => {
    switch (offerStatus) {
        case EProjectStatusType.PROJECT_STATUS_NEW:
            return `/${PATH.CALCULATOR}/${PATH.ROOF}/${PATH.MARKING}`;
        case EProjectStatusType.PROJECT_STATUS_DRAFT:
            return `/${PATH.RESULTS}`;
        case EProjectStatusType.PROJECT_STATUS_QUOTATIONS_CREATED:
        case EProjectStatusType.PROJECT_STATUS_QUOTATION_ELECTED:
            return `/${PATH.CONTRACTOR_OFFERS}`;
        default:
            return PATH.RESULTS;
    }
};

const ClientProjects: FC<IProps> = ({ name, isClient = false }) => {
    const navigate = useNavigate();

    const [projects, setProjects] = useState<IMyOffer[]>([]);

    const [, setCalculator] = useLocalStorage<ISavedProjectAddress | null>(CALCULATOR_STORAGE_KEY, null);
    const { data: clientProjects } = useGetClientProjects(isClient);
    const { data: agentProjects } = useGetAgentProjects(!isClient);

    const data = clientProjects || agentProjects;
    const offers = useMemo(() => data?.items, [data]);

    return (
        <>
            <Title name={name} isClient={true} />
            <SortQuotations projects={offers} setProjects={setProjects} />
            {projects && (
                <MyProjects
                    totalItems={data?.itemsFound || 0}
                    offers={projects}
                    mainButtonHandler={(offer) => {
                        const path = getPath(offer.status as EProjectStatusType);
                        setCalculator({ projectId: offer.item_id, address: offer?.address });
                        navigate(path, { state: { from: PATH.PRICING_MY_OFFERS } });
                    }}
                />
            )}
        </>
    );
};

export default ClientProjects;
