import App from './App';
import { createRoutesFromElements, Route, createBrowserRouter, Outlet, Navigate, useNavigate } from 'react-router-dom';

import { PATH } from 'paths';
import CalculatorPage from 'pages/calculator/CalculatorPage';
import Address from 'pages/calculator/calculator_section/address/Address';
import GetAddress from 'pages/calculator/calculator_section/address/GetAddress';
import Marking from 'pages/calculator/calculator_section/roof/Marking';
import PlacementElements from 'pages/calculator/calculator_section/roof/PlacementElements';
import Type from 'pages/calculator/calculator_section/roof/Type';
// import ElectricityConnection from 'pages/calculator/calculator_section/questionnaire/ElectricityConnection';
import RoofAccess from 'pages/calculator/calculator_section/questionnaire/RoofAccess';
import ErrorBoundary from 'components/molecules/ErrorBoundary';
import Funding from 'pages/calculator/calculator_section/questionnaire/Funding';
import MapProvider, { MapContext } from 'contexts/MapContext';
import Offers from 'pages/offers/Offers';
import Offer from 'pages/offers/Offer';
import Pricing from 'pages/Pricing';
import PricingEntrepreneur from 'pages/pricing/PricingEntrepreneur';
import PricingContractor from 'pages/pricing/PricingContractor';
import PersonalArea from 'pages/pricing/PersonalArea';
import PricingTransformers from 'pages/pricing/PricingTransformers';
import Results from 'pages/results/Results';
import Management from 'pages/pricing/Management';
import MyOffers from 'pages/pricing/MyOffers';
import { AuthContext } from 'contexts/AuthContext';
import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Box, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import texts from 'texts.json';
import InfoModal from 'components/molecules/InformationModal';
import useAuth from 'hooks/useAuth.hook';
interface IProps {
    children: ReactNode;
}

const ProtectedRoute: FC<IProps> = ({ children }) => {
    const { isCompany, isCompanyApproved, isCompanyApprovedForCalculator, isCompanyEmployee } = useAuth();
    const theme = useTheme();
    const navigate = useNavigate();
    const { setCalculator } = useContext(MapContext);

    const [notApprovedCompany, setNotApprovedCompany] = useState<boolean>(false);

    useEffect(() => {
        if (!isCompany || !isCompanyEmployee) return;
        if (!isCompanyApproved) {
            setNotApprovedCompany(true);
            return;
        }
        if (!isCompanyApprovedForCalculator) {
            navigate(`/${PATH.PRICING}/${PATH.MANAGEMENT}`, { replace: true });
            setCalculator(null);
            return;
        }
    }, [isCompany, isCompanyApproved, isCompanyApprovedForCalculator, isCompanyEmployee, navigate, setCalculator]);

    return (
        <>
            {notApprovedCompany ? (
                <InfoModal
                    isCloseIcon
                    open={notApprovedCompany}
                    onClose={() => {
                        setNotApprovedCompany(false);
                        navigate(`/${PATH.PRICING}/${PATH.MANAGEMENT}`, { replace: true });
                        setCalculator(null);
                    }}
                >
                    <Box sx={{ width: '100%', marginTop: '46px', textAlign: 'center' }}>
                        <Typography.Body2 color={theme.palette.primary.black}>{texts.notApprovedCompany}</Typography.Body2>
                    </Box>
                </InfoModal>
            ) : (
                children
            )}
        </>
    );
};

const ProtectedResultsRoute: FC<IProps> = ({ children }) => {
    const { user } = useContext(AuthContext);
    const isClient = !!user?.user.owner_client;
    if (isClient) return <Navigate to={`/${PATH.CALCULATOR}/${PATH.ADDRESS}`} replace />;
    return <>{children}</>;
};

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<App />} errorElement={<ErrorBoundary />}>
            <Route
                path={PATH.CALCULATOR}
                element={
                    <ProtectedRoute>
                        <MapProvider>
                            <CalculatorPage />
                        </MapProvider>
                    </ProtectedRoute>
                }
                errorElement={<ErrorBoundary />}
            >
                <Route path={PATH.ADDRESS} element={<Address />} errorElement={<ErrorBoundary />}>
                    <Route path={PATH.GET_ADDRESS} element={<GetAddress />} />
                </Route>
                <Route path={PATH.ROOF} element={<Outlet />} errorElement={<ErrorBoundary />}>
                    <Route path={PATH.MARKING} element={<Marking />} />
                    <Route path={PATH.PLACEMENT_ELEMENTS} element={<PlacementElements />} />
                    <Route path={PATH.TYPE} element={<Type />} />
                </Route>
                <Route path={PATH.QUESTIONNAIRE} element={<Outlet />} errorElement={<ErrorBoundary />}>
                    <Route path={PATH.ROOF_ACCESS} element={<RoofAccess />} />
                    {/* <Route path={PATH.ELECTRICITY_CONNECTION} element={<ElectricityConnection />} /> */}
                    <Route path={PATH.FUNDING} element={<Funding />} />
                </Route>
                <Route path='*' element={<div>NOT FOUND</div>} />
            </Route>
            <Route
                path={PATH.RESULTS}
                element={
                    <ProtectedResultsRoute>
                        <Results />
                    </ProtectedResultsRoute>
                }
                errorElement={<ErrorBoundary />}
            />
            <Route path={PATH.CONTRACTOR_OFFERS} element={<Offers />} errorElement={<ErrorBoundary />}>
                <Route index path={PATH.OFFER_ID} element={<Offer />} errorElement={<ErrorBoundary />} />
            </Route>
            <Route path={PATH.ENTREPRENEUR_OFFERS} element={<Offers />} errorElement={<ErrorBoundary />}>
                <Route path={PATH.OFFER_ID} element={<Offer />} errorElement={<ErrorBoundary />} />
            </Route>
            <Route path={PATH.PRICING} element={<Pricing />} errorElement={<ErrorBoundary />}>
                <Route path={PATH.PRICING_ENTREPRENEUR} element={<PricingEntrepreneur />} />
                <Route path={PATH.PRICING_MY_OFFERS} element={<MyOffers />} />
                <Route path={PATH.PRICING_CONTRACTOR} element={<PricingContractor />} />
                <Route path={PATH.PERSONAL_AREA} element={<PersonalArea />} />
                <Route path={PATH.PRICING_TRANSFORMERS} element={<PricingTransformers />} />
                <Route path={PATH.MANAGEMENT} element={<Management />} />
            </Route>
            <Route path='/' element={<Navigate to={`${PATH.CALCULATOR}/${PATH.ADDRESS}`} replace />} />
        </Route>
    )
);

export default router;
