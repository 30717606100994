import { useCalculatorLoader } from 'hooks/useLoading.hook';
import React, { createContext, useContext, useState, ReactNode, useEffect, useMemo } from 'react';

interface ICalculatorLoadingContext {
    currentStep: CalculatorStep | null;
    setCurrentStep: (step: CalculatorStep | null) => void;
}

export enum CalculatorStep {
    INITIAL = 0,
    SET_PROJECT = 1,
    CREATE_QUOTATIONS = 2,
    GET_QUOTATIONS = 3,
    GET_COMPARISON = 4,
    FINISH = 5,
}

const CalculatorLoadingContext = createContext<ICalculatorLoadingContext>({
    currentStep: null,
    setCurrentStep: () => {},
});

export const CalculatorLoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [currentStep, setCurrentStep] = useState<CalculatorStep | null>(null);
    const { loadingStep } = useCalculatorLoader();

    useEffect(() => {
        if (loadingStep === CalculatorStep.FINISH) {
            // When we reach FINISH, wait a bit then clear
            const timeoutId = setTimeout(() => {
                setCurrentStep(null);
            }, 1000);
            return () => clearTimeout(timeoutId);
        } else if (loadingStep !== null) {
            setCurrentStep(loadingStep);
        }
    }, [loadingStep]);

    const value = useMemo(() => ({ currentStep, setCurrentStep }), [currentStep, setCurrentStep]);
    return <CalculatorLoadingContext.Provider value={value}>{children}</CalculatorLoadingContext.Provider>;
};

export const useCalculatorLoading = () => {
    const context = useContext(CalculatorLoadingContext);
    if (!context) {
        throw new Error('useCalculatorLoading must be used within a CalculatorLoadingProvider');
    }
    return context;
};
