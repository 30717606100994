import React, { FC, MouseEvent, ReactNode, useContext, useEffect } from 'react';
import { AppLogo, Box, Button, Drawer, Icon } from 'components/atoms';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH } from 'paths';
import useCalculatorBackButton from 'hooks/useCalculatorBackButton';
import { GlobalModalsContext } from 'contexts/GlobalModalsProvider';
import useAuth from 'hooks/useAuth.hook';

interface IProps {
    children: ReactNode;
    openDrawer: boolean;
    setOpenDrawer: (value: boolean) => void;
    handleOpenAuthMenu: (e: MouseEvent<HTMLButtonElement>) => void;
}

const MobileAppBar: FC<IProps> = ({ children, handleOpenAuthMenu, openDrawer, setOpenDrawer }) => {
    const path = useLocation().pathname;
    const navigate = useNavigate();
    const isCalculator = useLocation().pathname.startsWith(`/${PATH.CALCULATOR}`);
    const { onOpen: onOpenRemainingSearchesModal } = useContext(GlobalModalsContext);
    const { user, isCompanyApprovedForCalculator, isCompanyApproved, isCompanyEmployee, isCompany } = useAuth();
    const { onBackButtonHandler } = useCalculatorBackButton();
    const calculatorPath = `/${PATH.CALCULATOR}/${PATH.ADDRESS}`;

    const isCalculatorAddress = path === calculatorPath;
    const isCalculatorButtonDisabled = (isCompany || isCompanyEmployee) && (!isCompanyApprovedForCalculator || !isCompanyApproved);

    const onNavigateToCalculator = () => {
        if (isCalculatorAddress || isCalculatorButtonDisabled) {
            return;
        }
        if (!user?.user) {
            navigate(calculatorPath);
        } else {
            onOpenRemainingSearchesModal();
        }
    };

    useEffect(() => {
        setOpenDrawer(false);
    }, [path, setOpenDrawer]);

    return (
        <>
            <Box px='21px' height='49px' display='flex' alignItems='center' justifyContent='space-between' bgcolor='primary.white'>
                {isCalculator && !isCalculatorAddress ? (
                    <Button.Back text='חזרה' onClick={onBackButtonHandler} />
                ) : (
                    <Button.Icon
                        onClick={() => {
                            setOpenDrawer(true);
                        }}
                    >
                        <Icon.Menu />
                    </Button.Icon>
                )}
                <AppLogo onClick={onNavigateToCalculator} />
                <Button.Icon onClick={handleOpenAuthMenu}>
                    <Icon.Account />
                </Button.Icon>
            </Box>
            <Drawer.Horizontal open={openDrawer} onClose={() => setOpenDrawer(false)} fullWidth padding='0'>
                {children}
            </Drawer.Horizontal>
        </>
    );
};

export default MobileAppBar;
