import { Box, Typography, Icon } from 'components/atoms';
import { useTheme } from '@mui/material';
import texts from 'texts.json';
import useUserType, { UserType } from 'hooks/useUserType.hook';
import { FC } from 'react';
import { useCalculatorLoading } from 'contexts/CalculatorLoadingContext';
import loader from 'assets/images/organuz_loader.gif';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IStepProps {
    text: string;
    iconType: string;
    isCompleted: boolean;
    isLoading: boolean;
}

interface ILoaderStep {
    text: string;
    iconType: string;
}

interface ILoaderConfig {
    title: string;
    subTitle: string;
    steps: ILoaderStep[];
}

type CalculatorDynamicLoader = {
    [key in UserType]: ILoaderConfig;
};

const Step: FC<IStepProps> = ({ text, iconType, isCompleted, isLoading }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    const renderIcon = () => {
        switch (iconType) {
            case 'LocalRegulation':
                return <Icon.LocalRegulation />;
            case 'ProductionPotential':
                return <Icon.ProductionPotential />;
            case 'Optimization':
                return <Icon.Optimization />;
            case 'CostsAndRevenue':
                return <Icon.CostsAndRevenue />;
            case 'PriceQuotes':
                return <Icon.PriceQuotes />;
            default:
                return null;
        }
    };

    const iconsSize = isDesktop ? 24 : 16;

    return (
        <Box display='flex' alignItems='center' columnGap={{ md: '18px', xs: '10px' }} mb={2}>
            <Box width={iconsSize} height={iconsSize} display='flex' alignItems='center' justifyContent='center'>
                {renderIcon()}
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                <Typography.Body4
                    font={isCompleted || isLoading ? theme.fontFamilies.bold : theme.fontFamilies.regular}
                    color={isCompleted || isLoading ? theme.palette.primary.black : theme.palette.primary.lightGrey}
                >
                    {text}
                </Typography.Body4>

                <Box width={iconsSize} height={iconsSize} display='flex' alignItems='center' justifyContent='center' ml={'18px'}>
                    {isCompleted ? <Icon.Done /> : isLoading ? <img width={'auto'} height={iconsSize} src={loader} alt='טוען...' /> : null}
                </Box>
            </Box>
        </Box>
    );
};

const SteppingLoader = () => {
    const theme = useTheme();
    const { currentStep } = useCalculatorLoading();
    const userType = useUserType();

    const loaderConfig = (texts.calculatorDynamicLoader as CalculatorDynamicLoader)[userType];

    if (!loaderConfig || currentStep === null) return null;

    return (
        <Box
            position='fixed'
            left={0}
            right={0}
            height={'100%'}
            bgcolor={theme.palette.primary.white}
            p={'20px'}
            zIndex={theme.zIndex.appBar - 1}
        >
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} mx='auto' mt={{ md: '100px', xs: '30px' }}>
                <Box textAlign='center' mb={'10px'}>
                    <Typography.XLargeTitle color={theme.palette.primary.purple}>{loaderConfig.title}</Typography.XLargeTitle>
                </Box>
                <Box textAlign='center' mb={'16px'}>
                    <Typography.Body2>{loaderConfig.subTitle}</Typography.Body2>
                </Box>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} m={'auto'} mt={'32px'} rowGap={'32px'}>
                    {loaderConfig.steps.map((step: ILoaderStep, index: number) => (
                        <Step
                            key={index}
                            text={step.text}
                            iconType={step.iconType}
                            isCompleted={index < currentStep}
                            isLoading={index === currentStep}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default SteppingLoader;
