import React, { cloneElement, Dispatch, FC, ReactElement, ReactNode, SetStateAction, useState } from 'react';
import { Box, Button, Icon, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';

interface IProps {
    title: string;
    subtitle: { main: string; secondary?: string };
    children: ReactElement;
    fundingComponent?: ReactNode;
    setIsFullScreen?: Dispatch<SetStateAction<boolean>>;
}

const HeaderWithFullscreen: FC<IProps> = ({ children, title, subtitle, setIsFullScreen, fundingComponent }) => {
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const theme = useTheme();

    const sx = fullscreen
        ? {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
              backgroundColor: 'white',
              height: '100vh',
              width: '100vw',
              padding: '60px',
          }
        : {
              height: '100%',
              width: '100%',
          };

    return (
        <Box sx={sx}>
            <Box display='flex' justifyContent='space-between' mb='10px'>
                <Box width={'95%'} display='flex' flexDirection='column' gap='8px'>
                    <Typography.Body2 color={theme.palette.primary.purple}>{title}</Typography.Body2>
                    <Typography.Body5 font={theme.fontFamilies.light}>
                        {subtitle.main}
                        <br />
                        {subtitle.secondary}
                    </Typography.Body5>
                    {fundingComponent && <Box display='flex'>{fundingComponent}</Box>}
                </Box>
                <Box alignSelf='end'>
                    <Button.Icon
                        onClick={() => {
                            setFullscreen((prev) => !prev);
                            setIsFullScreen && setIsFullScreen((prev: boolean) => !prev);
                        }}
                    >
                        {fullscreen ? <Icon.ZoomOut /> : <Icon.Fullscreen />}
                    </Button.Icon>
                </Box>
            </Box>
            <>{cloneElement(children, { fullscreen })}</>
        </Box>
    );
};

export default HeaderWithFullscreen;
