import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosGet, axiosPost } from 'api/axios';
import { API_ROUTES } from 'api/apiRoutes';
import { AxiosResponse } from 'axios';
import {
    GetProjectQuotationRes,
    GetProjectComparisonRes,
    GetQuotationDetailsRes,
    ElectQuotationConstractorRes,
    ElectQuotationEntrepreneurRes,
    GetEntrepreneurQuotationDetailsRes,
    SetProjectClientNameRes,
    SetProjectClientNameParams,
    CreateClientQuotationsRes,
} from 'models/api/offers';
import { getToken } from 'utils/getToken';
import { IDownloadQuotationReq } from 'models/api/common';
import { processGetProjectQuotationsResData } from 'data/offersData';
import { IProjectQuotation } from 'models/OffersData';
import texts from 'texts.json';

export const useGetProjectQuotations = (itemId: string, enabled = true) => {
    const { data, refetch } = useQuery(
        [API_ROUTES.GET_PROJECT_QUOTATIONS, itemId],
        (): Promise<AxiosResponse<GetProjectQuotationRes, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_PROJECT_QUOTATIONS}&item_id=${itemId}`),
        { enabled }
    );
    return { data: processGetProjectQuotationsResData(data?.data) || ({} as IProjectQuotation), refetch };
};

export const useCreateProjectQuotations = () => {
    const { mutate, mutateAsync } = useMutation(
        [API_ROUTES.CREATE_PROJECT_QUOTATIONS],
        (itemId: string): Promise<AxiosResponse<CreateClientQuotationsRes, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.CREATE_PROJECT_QUOTATIONS}&item_id=${itemId}`)
    );
    return { mutate, mutateAsync };
};

export const useGetProjectComparison = (itemId: string, enabled = true) => {
    const { data, refetch } = useQuery(
        [API_ROUTES.GET_PROJECT_COMPARISON, itemId],
        (): Promise<AxiosResponse<GetProjectComparisonRes, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_PROJECT_COMPARISON}&item_id=${itemId}`),
        { enabled }
    );
    return { data, refetch };
};

export const useGetQuotationDetails = (itemId: string | undefined, enabled = true) => {
    const { data } = useQuery(
        [API_ROUTES.GET_QUOTATION_DETAILS],
        (): Promise<AxiosResponse<GetQuotationDetailsRes, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_QUOTATION_DETAILS}&item_id=${itemId}`),
        { enabled }
    );
    return { data };
};

export const useGetEntrepreneurQuotationDetails = (itemId: string | undefined, enabled = true) => {
    const { data } = useQuery(
        [API_ROUTES.GET_ENTREPRENEUR_QUOTATION_DETAILS],
        (): Promise<AxiosResponse<GetEntrepreneurQuotationDetailsRes, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.GET_ENTREPRENEUR_QUOTATION_DETAILS}&item_id=${itemId}`),
        { enabled }
    );
    return { data };
};

export const useElectContractorQuotation = () => {
    const { mutate } = useMutation(
        [API_ROUTES.ELECT_CONSTRACTOR_QUOTATION],
        (itemId: string): Promise<AxiosResponse<ElectQuotationConstractorRes, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.ELECT_CONSTRACTOR_QUOTATION}`, {
                item_id: itemId,
            }),
        { meta: { defaultError: texts.common.offersQuantityError } }
    );
    return { mutate };
};

export const useElectEntrepreneurQuotation = () => {
    const { mutate } = useMutation(
        [API_ROUTES.ELECT_ENTREPRENEUR_QUOTATION],
        (itemId: string): Promise<AxiosResponse<ElectQuotationEntrepreneurRes, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.ELECT_ENTREPRENEUR_QUOTATION}`, {
                item_id: itemId,
            }),
        { meta: { defaultError: texts.common.offersQuantityError } }
    );
    return { mutate };
};

export const useSetProjectClientName = () => {
    const { mutate } = useMutation(
        [API_ROUTES.SET_PROJECT_CLIENT_NAME],
        ({ itemId, clientName }: SetProjectClientNameParams): Promise<AxiosResponse<SetProjectClientNameRes, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.SET_PROJECT_CLIENT_NAME}`, {
                item_id: itemId,
                item: {
                    client_name: clientName,
                },
            })
    );
    return { mutate };
};

export const useAgentSetProjectClientName = () => {
    const { mutate, mutateAsync } = useMutation(
        [API_ROUTES.AGENT_SET_PROJECT_CLIENT_NAME],
        ({ itemId, clientName }: SetProjectClientNameParams): Promise<AxiosResponse<SetProjectClientNameRes, any>> =>
            axiosPost(`?action=token&token=${getToken()}&call=${API_ROUTES.AGENT_SET_PROJECT_CLIENT_NAME}`, {
                item_id: itemId,
                item: {
                    client_name: clientName,
                },
            })
    );
    return { mutate, mutateAsync };
};

export const useDownloadQuotation = () => {
    const { mutate } = useMutation(
        [API_ROUTES.DOWNLOAD_QUOTATION],
        ({ itemId, type }: IDownloadQuotationReq): Promise<AxiosResponse<ArrayBuffer, any>> =>
            axiosGet(`?action=token&token=${getToken()}&call=${API_ROUTES.DOWNLOAD_QUOTATION}&item_id=${itemId}&field=${type}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf',
                },
            })
    );
    return { mutate };
};

export const useDownloadEntrepreneurQuotation = () => {
    const { mutate } = useMutation(
        [API_ROUTES.DOWNLOAD_ENTREPRENEUR_QUOTATION],
        ({ itemId, type }: IDownloadQuotationReq): Promise<AxiosResponse<ArrayBuffer, any>> =>
            axiosGet(
                `?action=token&token=${getToken()}&call=${API_ROUTES.DOWNLOAD_ENTREPRENEUR_QUOTATION}&item_id=${itemId}&field=${type}`,
                {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/pdf',
                    },
                }
            )
    );
    return { mutate };
};
