import { ApiResponseYesNo, Response } from 'models/api/common';
import { CompanyApprovedStatus, CompanyStatus, CompanyType } from 'models/api/management';

export interface LoginParams {
    sms_phone: string;
    sms_code?: string;
    regulations_approval?: ApiResponseYesNo;
}

export interface LoginRes extends Response {
    token: string;
    user: IUser;
    regulations_approval?: ApiResponseYesNo;
    owner_company_employee?: boolean;
}

export interface IUser {
    item_id: string;
    owner_company?: string;
    owner_client?: string;
    owner_agent?: string;
    owner_company_employee?: string;
    company_status?: CompanyStatus;
    company_type?: CompanyType[];
    fullname: string;
    constractor_approved_status: CompanyApprovedStatus[] | null;
    entrepreneur_approved_status: CompanyApprovedStatus[] | null;
}

export interface SignUpParams {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    regulations_approval: ApiResponseYesNo;
    advertising_content: ApiResponseYesNo;
}

export interface IAuthContext {
    user: LoginRes | undefined;
    showDialog: Dialogs | null;
    setShowDialog: (value: Dialogs | null) => void;
    handleLogin: (user: LoginRes, saveToStorage: boolean, callback: () => void) => void;
    handleLogout: () => void;
}

export enum Dialogs {
    LOGIN = 'LOGIN',
    SIGN_UP = 'SIGN_UP',
    VERIFICATION_CODE = 'VERIFICATION_CODE',
}
