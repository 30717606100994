import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosPost } from 'api/axios';
import { API_ROUTES } from 'api/apiRoutes';
import { AxiosResponse } from 'axios';
import {
    CreateProjectReq,
    SetProjectReq,
    GetProjectRes,
    ISetProjectCreatedByRes,
    ISetEconomicModelReq,
    ISetEconomicModelRes,
} from 'models/api/calculator';
import { getToken } from 'utils/getToken';
import { DEV_AUTH_TOKEN } from 'utils/envUtils';
import { SetCreateRes } from 'models/api/common';
import { processGetCalculatorResData } from 'data/CalculatorProcessingData';
import { GetProject } from 'models/CalculatorModel.model';
import { useMutationFromKey } from 'hooks/useMutationFromKey';
import { useLocation } from 'react-router-dom';
import { PATH } from 'paths';

export const useCreateProject = () => {
    const { mutate, data } = useMutation(
        [API_ROUTES.CREATE_PROJECT],
        ({
            address,
            itemEast,
            itemNorth,
            propertyType,
            ambassador,
            ambassador_type,
        }: CreateProjectReq): Promise<AxiosResponse<SetCreateRes, any>> => {
            const query = [];
            if (ambassador && ambassador_type) {
                query.push(`&item[ambassador_type]=${ambassador_type}&item[ambassador]=${ambassador}`);
            }

            return axiosPost(
                '/',
                `action=token&token=${getToken() || DEV_AUTH_TOKEN}&call=${
                    API_ROUTES.CREATE_PROJECT
                }&item[address]=${address}&item[itm_east]=${Math.floor(Number(itemEast))}&item[itm_north]=${Math.floor(
                    Number(itemNorth)
                )}&item[property_type]=${propertyType}${query.join('')}`
            );
        }
    );
    return { mutate, data };
};

export const useSetProjectCalculator = () => {
    const { mutate, data } = useMutation(
        [API_ROUTES.SET_PROJECT_CALCULATOR],
        ({ itemId, roofAccess, electricalConnection, financingOffer }: SetProjectReq): Promise<AxiosResponse<SetCreateRes, any>> => {
            return axiosPost(
                '/',
                `action=token&token=${getToken() || DEV_AUTH_TOKEN}&call=${
                    API_ROUTES.SET_PROJECT_CALCULATOR
                }&item_id=${itemId}&item[roof_access]=${roofAccess || ''}&item[property_electrical_connection_size]=${
                    electricalConnection || ''
                }&item[want_financing_offer]=${financingOffer || ''}`
            );
        }
    );
    return { mutate, data };
};

export const useGetProjectCalculator = (itemId?: string, enabled = true): { data: GetProject } => {
    const { data } = useQuery(
        [API_ROUTES.GET_PROJECT_CALCULATOR],
        (): Promise<AxiosResponse<GetProjectRes, any>> =>
            axiosPost(
                '/',
                `action=token&token=${getToken() || DEV_AUTH_TOKEN}&call=${API_ROUTES.GET_PROJECT_CALCULATOR}&item_id=${itemId}`
            ),
        { enabled }
    );
    return { data: processGetCalculatorResData(data?.data) || ({} as GetProject) };
};

export const useGetProjectCalculatorMutation = () => {
    const queryClient = useQueryClient();
    const { mutateAsync } = useMutation({
        mutationKey: [API_ROUTES.GET_PROJECT_CALCULATOR],
        mutationFn: (itemId?: string): Promise<AxiosResponse<GetProjectRes>> => {
            return axiosPost(
                '/',
                `action=token&token=${getToken() || DEV_AUTH_TOKEN}&call=${API_ROUTES.GET_PROJECT_CALCULATOR}&item_id=${itemId}`
            );
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [API_ROUTES.GET_PROJECT_CALCULATOR] });
        },
    });
    return { mutateAsync };
};

export const useSetProjectCreatedBy = () => {
    const { mutateAsync } = useMutation(
        [API_ROUTES.SET_PROJECT_CREATED_BY],
        (item_id: string): Promise<AxiosResponse<ISetProjectCreatedByRes>> =>
            axiosPost('/', `action=token&token=${getToken()}&call=${API_ROUTES.SET_PROJECT_CREATED_BY}&item_id=${item_id}`)
    );
    return { mutateAsync };
};

export const useSetProjectEconomicModelFieldsMutation = () => {
    const queryClient = useQueryClient();
    const pathname = useLocation().pathname;
    const { mutateState } = useMutationFromKey({
        mutationKey: API_ROUTES.SET_PROJECT_ECONOMIC_MODEL_FIELDS,
    });
    const { mutateAsync } = useMutation(
        [API_ROUTES.SET_PROJECT_ECONOMIC_MODEL_FIELDS],
        (data: ISetEconomicModelReq): Promise<AxiosResponse<ISetEconomicModelRes>> =>
            axiosPost(
                '/',
                `action=token&token=${getToken()}&call=${API_ROUTES.SET_PROJECT_ECONOMIC_MODEL_FIELDS}&item_id=${
                    data.item_id
                }&item[funding_rate]=${data.funding_rate}&item[loan_period]=${data.loan_period}&item[loan_interest_rate]=${
                    data.loan_interest_rate
                }`
            ),
        {
            cacheTime: Infinity,
            onSuccess: () => {
                if (pathname.includes(PATH.OFFERS)) {
                    queryClient.invalidateQueries({ queryKey: [API_ROUTES.GET_PROJECT_QUOTATIONS] });
                }
                if (pathname.includes(PATH.RESULTS)) {
                    queryClient.invalidateQueries({ queryKey: [API_ROUTES.GET_PROJECT_CALCULATOR] });
                }
            },
            onSettled: () => {
                // Don't clear the mutation cache
                return;
            },
        }
    );
    return { data: mutateState?.data?.data?.item, mutateAsync };
};

export const useGetProjectEconomicModelFields = () => {
    const { mutateState } = useMutationFromKey({
        mutationKey: API_ROUTES.SET_PROJECT_ECONOMIC_MODEL_FIELDS,
    });

    return {
        data: mutateState?.data?.data as ISetEconomicModelRes,
    };
};
