import { useQueryClient } from '@tanstack/react-query';
import { useState, useEffect } from 'react';

export const useMutationFromKey = ({ mutationKey }: { mutationKey: string }) => {
    const queryClient = useQueryClient();
    const [mutateState, setMutateState] = useState<any>();

    useEffect(() => {
        // Get initial state from cache if it exists
        const mutationCache = queryClient.getMutationCache();
        const existingMutation = mutationCache.getAll().find((mutation) => mutation.options.mutationKey?.[0] === mutationKey);
        if (existingMutation?.state?.data) {
            setMutateState(existingMutation.state);
        }

        const unsubscribe = mutationCache.subscribe(({ type, mutation }) => {
            if (type === 'updated' && mutation.options.mutationKey?.[0] === mutationKey) {
                setMutateState(mutation?.state);
            }
        });

        return () => unsubscribe();
    }, [mutationKey, queryClient]);

    return { mutateState };
};
