import { Box } from 'components/atoms';
import ClientProjects from 'components/organisms/myOffers/ClientProjects';
import CompanyProjects from 'components/organisms/myOffers/CompanyProjects';
import useAuth from 'hooks/useAuth.hook';

const MyOffers = () => {
    const { isClient, isAgent, isCompany, isCompanyEmployee, user } = useAuth();
    const name = user?.user.fullname || '';
    let component = null;
    switch (true) {
        case isClient: {
            component = <ClientProjects name={name} isClient />;
            break;
        }
        case isAgent: {
            component = <ClientProjects name={name} />;
            break;
        }
        case isCompany || isCompanyEmployee: {
            component = <CompanyProjects name={name} />;
            break;
        }
        default: {
            component = null;
            break;
        }
    }

    return (
        <Box display={'flex'} justifyContent={'center'}>
            <Box width='100%' maxWidth={{ sx: '100%', md: 1138 }}>
                {component}
            </Box>
        </Box>
    );
};

export default MyOffers;
