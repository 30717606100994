import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { API_ROUTES } from 'api/apiRoutes';
import { CalculatorStep } from 'contexts/CalculatorLoadingContext';
import { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth.hook';
import { useLocation } from 'react-router-dom';
import { PATH } from 'paths';

const ignoreQueries = [API_ROUTES.GET_COMPANY_PROJECT_IMAGES, API_ROUTES.GET_REMAINING_PROJECTS];

const ignoreMutations = [
    API_ROUTES.CREATE_PROJECT_IMAGE,
    API_ROUTES.SET_PROJECT_IMAGE,
    API_ROUTES.DELETE_PROJECT_IMAGE,
    API_ROUTES.UPLOAD_PROJECT_IMAGE,
    // API_ROUTES.CREATE_CONTACT_MESSAGE,
    // API_ROUTES.CREATE_CLIENT,
    // API_ROUTES.LOGIN,
];

const agentIgnoreQueries = [API_ROUTES.SET_PROJECT_CALCULATOR, API_ROUTES.GET_PROJECT_CALCULATOR];

const resultsTransitionToOffersPageIgnoreQueries = [
    API_ROUTES.GET_PROJECT_QUOTATIONS,
    API_ROUTES.GET_PROJECT_COMPARISON,
    API_ROUTES.CREATE_PROJECT_QUOTATIONS,
];

export const useCalculatorLoader = () => {
    const [showFinish, setShowFinish] = useState(false);
    const [showFakeLoaders, setShowFakeLoaders] = useState(false);
    const [step, setStep] = useState<CalculatorStep | null>(null);
    const { isClient } = useAuth();
    const { pathname } = useLocation();
    const isFunding = pathname.includes(PATH.FUNDING);
    const isSetProjectCalculator = useIsMutating({
        mutationKey: [API_ROUTES.SET_PROJECT_CALCULATOR],
    });

    const isGetProjectCalculator = useIsFetching({
        queryKey: [API_ROUTES.GET_PROJECT_CALCULATOR],
    });

    const isCreateProjectQuotations = useIsMutating({
        mutationKey: [API_ROUTES.CREATE_PROJECT_QUOTATIONS],
    });

    const isGetProjectQuotations = useIsFetching({
        queryKey: [API_ROUTES.GET_PROJECT_QUOTATIONS],
    });

    const isGetProjectComparison = useIsFetching({
        queryKey: [API_ROUTES.GET_PROJECT_COMPARISON],
    });

    useEffect(() => {
        const updateStep = () => {
            if (!!isSetProjectCalculator && isFunding) {
                setStep(CalculatorStep.SET_PROJECT);
            } else if (!!isGetProjectCalculator) {
                setStep(CalculatorStep.CREATE_QUOTATIONS);
                setTimeout(() => {
                    setShowFakeLoaders(true);
                }, 1000);
            }
        };
        const clientsSteps = () => {
            if (!!isSetProjectCalculator && isFunding) {
                setStep(CalculatorStep.SET_PROJECT);
            } else if (!!isCreateProjectQuotations) {
                setStep(CalculatorStep.CREATE_QUOTATIONS);
            } else if (!!isGetProjectQuotations) {
                setStep(CalculatorStep.GET_QUOTATIONS);
            } else if (!!isGetProjectComparison) {
                setStep(CalculatorStep.GET_COMPARISON);
                setTimeout(() => {
                    setShowFinish(true);
                }, 1000);
            } else if (showFinish) {
                setStep(CalculatorStep.FINISH);
            }
        };
        if (isClient) {
            clientsSteps();
        } else {
            updateStep();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isSetProjectCalculator,
        isCreateProjectQuotations,
        isGetProjectCalculator,
        isGetProjectQuotations,
        isGetProjectComparison,
        showFinish,
        isClient,
    ]);

    useEffect(() => {
        if (showFakeLoaders && !isClient) {
            const steps = [CalculatorStep.GET_QUOTATIONS, CalculatorStep.GET_COMPARISON, CalculatorStep.FINISH];
            let currentStepIndex = 0;

            const moveToNextStep = () => {
                if (currentStepIndex < steps.length) {
                    setStep(steps[currentStepIndex]);
                    currentStepIndex++;
                    if (currentStepIndex < steps.length) {
                        timeoutId = setTimeout(moveToNextStep, 1000);
                    } else {
                        setShowFakeLoaders(false);
                    }
                }
            };

            let timeoutId = setTimeout(moveToNextStep, 1000);

            return () => {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
            };
        }
    }, [showFakeLoaders, isClient]);

    return {
        loadingStep: step,
    };
};

const useLoading = () => {
    const { isAgent } = useAuth();
    const ignoreQueriesByUserType = isAgent ? agentIgnoreQueries : [...resultsTransitionToOffersPageIgnoreQueries, ...agentIgnoreQueries];

    const isFetching = useIsFetching({
        predicate: (mutation) => {
            const queryKey = mutation.options.queryKey?.[0] as string;
            return !ignoreQueries.includes(queryKey) && !ignoreQueriesByUserType.includes(queryKey);
        },
    });

    const isMutation = useIsMutating({
        predicate: (mutation) => {
            const mutationKey = mutation.options.mutationKey?.[0] as string;
            return !ignoreMutations.includes(mutationKey) && !ignoreQueriesByUserType.includes(mutationKey);
        },
    });

    return !!isFetching || !!isMutation;
};

export const useLoadingGetProjectCalculator = () => {
    const isFetching = useIsFetching({
        queryKey: [API_ROUTES.GET_PROJECT_CALCULATOR],
    });
    const isMutating = useIsMutating({
        mutationKey: [API_ROUTES.SET_PROJECT_ECONOMIC_MODEL_FIELDS],
    });
    return !!isFetching || !!isMutating;
};

export default useLoading;
