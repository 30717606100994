import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { CompanyApprovedStatus, CompanyStatus } from 'models/api/management';

const useAuth = () => {
    const { user } = useContext(AuthContext);

    const isCompany = !!user?.user.owner_company;
    const isCompanyEmployee = !!user?.user.owner_company_employee;
    const isAgent = !!user?.user.owner_agent;
    const isClient = !!user?.user.owner_client;
    const isCompanyApproved = user?.user.company_status === CompanyStatus.APPROVED;
    const isConstractorApprovedForCalculator = user?.user.constractor_approved_status?.includes(CompanyApprovedStatus.PERSONAL);
    const isEntrepreneurApprovedForCalculator = user?.user.entrepreneur_approved_status?.includes(CompanyApprovedStatus.PERSONAL);
    const isCompanyApprovedForCalculator = !!isConstractorApprovedForCalculator || !!isEntrepreneurApprovedForCalculator;
    return { user, isCompany, isAgent, isClient, isCompanyEmployee, isCompanyApproved, isCompanyApprovedForCalculator };
};

export default useAuth;
