import { useTheme } from '@mui/material';
import { Box, Dialog } from 'components/atoms';
import EconomicModelTemplate from 'components/templates/EconomicModelTemplate';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { FC, ReactNode } from 'react';
import texts from 'texts.json';

interface IProps {
    economicModel: any;
    fundingComponent: ReactNode;
    onClose(): void;
}
const EconomicModel: FC<IProps> = ({ economicModel, onClose, fundingComponent }) => {
    const isDesktop = useIsDesktop();
    const { table_title } = texts.common.economic_model;
    const chartLabels = { xAxisLabel: 'שנים', yAxisLabel: 'הכנסות ₪' };
    const theme = useTheme();

    const economicModelTemplate = economicModel && (
        <EconomicModelTemplate
            chartLabels={chartLabels}
            mobileLabels={economicModel.mobileCategories}
            headMobileTitles={economicModel.mobileHeadTitles}
            open={!!economicModel}
            onClose={onClose}
            npvRow={economicModel.npvRow}
            chartDataSet={economicModel.chart}
            tableTitle={table_title}
            economicModel={economicModel.data}
            tableDataCategories={economicModel.categories}
            tableTotalRows={economicModel.total}
            dialogBackgroundMobile={theme.palette.primary.greyBackground}
            fundingComponent={fundingComponent}
        />
    );

    return isDesktop && !!economicModel ? (
        <Dialog isEconomicModel open={!!economicModel} onClose={onClose}>
            <Box px={'30px'} pb={'5px'}>
                {economicModelTemplate}
            </Box>
        </Dialog>
    ) : (
        economicModelTemplate
    );
};

export default EconomicModel;
