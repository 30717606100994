import useAuth from 'hooks/useAuth.hook';

export enum UserType {
    CLIENT = 'client',
    AGENT = 'agent',
    COMPANY = 'company',
}

const useUserType = (): UserType => {
    const { user } = useAuth();

    if (!user?.user) return UserType.CLIENT;

    const { owner_company, owner_company_employee, owner_agent } = user.user;

    // Company type includes both company owners and employees
    if (owner_company || owner_company_employee) {
        return UserType.COMPANY;
    }

    if (owner_agent) {
        return UserType.AGENT;
    }

    // Default to client type (includes owner_client)
    return UserType.CLIENT;
};

export default useUserType;
