import React, { FC } from 'react';
import { Box, TextField } from 'components/atoms';
import { Control, Controller } from 'react-hook-form';
import { TextFieldSize, TextFieldType } from 'components/atoms/TextField';
import { InputBaseComponentProps, useTheme } from '@mui/material';

interface IProps {
    control: Control<any, any>;
    name: string;
    size?: TextFieldSize;
    multiline?: boolean;
    rows?: number;
    rules?: any;
    placeholder?: string;
    type?: TextFieldType;
    disabled?: boolean;
    onBlur?: () => void;
    step?: number;
    maxLength?: number;
    inputProps?: InputBaseComponentProps | undefined;
}

const ControllerTextField: FC<IProps> = ({
    control,
    name,
    size = TextFieldSize.Small,
    multiline,
    rows,
    rules,
    placeholder,
    type,
    disabled,
    onBlur,
    step,
    maxLength,
    inputProps,
}) => {
    const theme = useTheme();

    return (
        <Box onClick={(e) => e.stopPropagation()}>
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { onChange, value, ref, name }, fieldState: { error } }) => {
                    return (
                        <TextField
                            inputRef={ref}
                            size={size}
                            font={theme.fontFamilies.light}
                            textAlign='start'
                            value={value}
                            onChange={onChange}
                            name={name}
                            multiline={multiline}
                            rows={rows}
                            placeholder={placeholder}
                            helperText={error?.message || ''}
                            type={type}
                            disabled={disabled}
                            onBlur={onBlur}
                            step={step}
                            maxLength={maxLength}
                            inputProps={inputProps}
                        />
                    );
                }}
            />
        </Box>
    );
};

export default ControllerTextField;
