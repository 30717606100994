export const API_ROUTES = {
    ECHO: 'echo',
    CREATE_PROJECT: 'create_project',
    SET_PROJECT_CALCULATOR: 'set_project_calculator',
    GET_PROJECT_CALCULATOR: 'get_project_calculator',
    GET_PROJECT_QUOTATIONS: 'get_project_quotations',
    CREATE_PROJECT_QUOTATIONS: 'create_project_quotations',
    GET_PROJECT_COMPARISON: 'get_project_comparison',
    SET_PROJECT_CREATED_BY: 'set_project_created_by',
    SET_PROJECT_ECONOMIC_MODEL_FIELDS: 'set_project_economic_model_fields',

    GET_CONTRACTOR_PRICE_LISTS: 'get_constractor_price_lists',
    CREATE_CONTRACTOR_PRICE_LIST: 'create_constractor_price_list',
    SET_CONTRACTOR_PRICE_LIST: 'set_constractor_price_list',
    GET_CONSTRACTOR_SIMULATOR: 'get_constractor_simulator',
    DELETE_CONSTRACTOR_PRICE_LIST: 'delete_constractor_price_list',
    GET_QUOTATION_DETAILS: 'get_quotation',
    GET_ENTREPRENEUR_QUOTATION_DETAILS: 'get_entrepreneur_quotation',
    GET_COMPANY_DETAILS: 'get_company_details',
    UPLOAD_COMPANY_DETAILS: 'upload_company_details',

    LOGIN: 'login',
    LOGOUT: 'logout',
    CREATE_CLIENT: 'create_client',
    CLIENT_REGULATIONS_APPROVAL: 'client_regulations_approval',
    AGENT_REGULATIONS_APPROVAL: 'agent_regulations_approval',
    COMPANY_REGULATIONS_APPROVAL: 'company_regulations_approval',
    COMPANY_EMPLOYEE_REGULATIONS_APPROVAL: 'company_employee_regulations_approval',

    CREATE_RECOMMENDATION: 'create_recommendation',
    SET_RECOMMENDATION: 'set_recommendation',
    DELETE_RECOMMENDATION: 'delete_recommendation',
    CREATE_PROJECT_IMAGE: 'create_project_image',
    SET_PROJECT_IMAGE: 'set_project_image',
    DELETE_PROJECT_IMAGE: 'delete_project_image',
    UPLOAD_PROJECT_IMAGE: 'upload_project_image',
    SET_COMPANY_DETAILS: 'set_company_details',
    COMPANY_REGISTRATION_READY: 'company_registration_ready',
    GET_COMPANY_RECOMMENDATIONS: 'get_company_recommendations',
    GET_COMPANY_PROJECT_IMAGES: 'get_company_project_images',
    ELECT_CONSTRACTOR_QUOTATION: 'elect_constractor_quotation',
    ELECT_ENTREPRENEUR_QUOTATION: 'elect_entrepreneur_quotation',
    SET_PROJECT_CLIENT_NAME: 'set_project_client_name',
    AGENT_SET_PROJECT_CLIENT_NAME: 'agent_set_project_client_name',
    DOWNLOAD_QUOTATION: 'download_quotation',
    DOWNLOAD_ENTREPRENEUR_QUOTATION: 'download_entrepreneur_quotation',
    CREATE_CONTACT_MESSAGE: 'create_contact_message',

    GET_ENTREPRENEUR_PRICE_LISTS: 'get_entrepreneur_price_lists',
    CREATE_ENTREPRENEUR_PRICE_LIST: 'create_entrepreneur_price_list',
    DELETE_ENTREPRENEUR_PRICE_LIST: 'delete_entrepreneur_price_list',
    SET_ENTREPRENEUR_PRICE_LIST: 'set_entrepreneur_price_list',
    GET_ENTREPRENEUR_SIMULATOR: 'get_entrepreneur_simulator',
    SET_ENTREPRENEUR_CHOSEN_CONSTRUCTION_PRICE_LIST: 'set_entrepreneur_chosen_construction_price_list',

    GET_REMAINING_PROJECTS: 'get_remaining_projects',
    GET_COMPANY_SETTINGS: 'get_company_settings',
    GET_CLIENT_SETTINGS: 'get_client_settings',
    DOWNLOAD_SETTINGS_FILES: 'download_settings_files',
    DOWNLOAD_COMPANY_FILES: 'download_company_files',

    // my offers
    GET_CLIENT_PROJECTS: 'get_client_projects',
    GET_AGENT_PROJECTS: 'get_agent_projects',
    GET_COMPANY_PROJECTS: 'get_company_projects',
    GET_COMPANY_QUOTATIONS: 'get_company_quotations',
    GET_COMPANY_ENTREPRENEUR_QUOTATIONS: 'get_company_entrepreneur_quotations',
};
