/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LegendItem,
    TooltipPositionerFunction,
    ChartType,
    ChartData,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Box } from 'components/atoms';
import { options } from 'components/atoms/chart/config';
import ChartLegend from 'components/atoms/chart/Legend';
import Slider from 'components/atoms/chart/Slider';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { FC, useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';

declare module 'chart.js' {
    interface TooltipPositionerMap {
        top: TooltipPositionerFunction<ChartType>;
    }
}

Tooltip.positioners.top = function (items) {
    if (!items?.length) {
        return false;
    }
    const x = items[0].element.x;

    return {
        x: x - 5,
        y: -40,
        xAlign: 'center',
        yAlign: 'top',
    };
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, zoomPlugin, Legend);

const sx = {
    legendContainer: {
        margin: '10px 50px 0 10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
};

export interface IChartLabels {
    xAxisLabel: string;
    yAxisLabel: string;
}

interface IProps {
    isFullScreen?: boolean;
    isLegend?: boolean;
    chartLabels: IChartLabels;
    data: ChartData<'bar', number[]>;
}

const Chart: FC<IProps> = ({ isFullScreen, data, chartLabels, isLegend = true }) => {
    const [value, setValue] = useState(460);
    const chartRef = useRef<ChartJS>(null);
    const [legendItems, setLegend] = useState<LegendItem[] | undefined>();
    const isDesktop = useIsDesktop();

    const onChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
        const chart = chartRef.current;
        if (chart) {
            chart.pan({ x: +newValue }, undefined, 'active');
        }
    };

    useEffect(() => {
        const chart = chartRef.current;
        if (chart?.legend && isLegend) {
            setLegend(chart.legend.legendItems);
        }
    }, [isLegend]);

    useEffect(() => {
        const chart = chartRef?.current;
        if (chart?.options?.scales) {
            let xScale = chart.scales.x;
            let yScale = chart.scales.y;
            chart.options.scales = {
                x: {
                    max: isFullScreen ? undefined : 12,
                    stacked: true,
                    title: {
                        display: true,
                        text: chartLabels.xAxisLabel,
                        font: {
                            size: 18,
                            weight: 'bold',
                        },
                    },
                },
                y: {
                    stacked: true,
                    title: {
                        display: true,
                        text: chartLabels.yAxisLabel,
                        font: {
                            size: 18,
                            weight: 'bold',
                        },
                    },
                },
            };
            chart.update();
            xScale = chart.scales.newId;
            yScale = chart.scales.y;
        }
    }, [chartLabels.xAxisLabel, chartLabels.yAxisLabel, isFullScreen]);

    return (
        <>
            <Box height={isDesktop ? '80%' : '73%'}>
                <Bar ref={chartRef} data={data} options={options} />
            </Box>
            <Box sx={sx.legendContainer} pt={isFullScreen ? '40px' : ''}>
                {
                    <Box display='flex' width='100%' justifyContent='center'>
                        {!isFullScreen && <Slider value={value} onChange={onChange} />}
                    </Box>
                }
            </Box>
            {legendItems && <ChartLegend legendItems={legendItems} />}
        </>
    );
};

export default Chart;
