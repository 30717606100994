import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Paper, Typography } from 'components/atoms';
import ContentSection from 'components/molecules/ContentSection';
import { useTheme } from '@mui/material';
import texts from 'texts.json';
import { ButtonSize } from 'components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import Description from 'components/molecules/Description';
import { useSetProjectCalculator } from 'api/queries/calculator';
import ContentSectionMobileQuestionnaire from 'components/molecules/ContentSectionMobileQuestionnaire';
import { MapContext } from 'contexts/MapContext';
import { ApiResponseYesNo } from 'models/api/common';
import { AuthContext } from 'contexts/AuthContext';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { ISavedProjectAddress } from 'models/MapData';
import InfoModal from 'components/molecules/InformationModal';
import { useCreateProjectQuotations } from 'api/queries/offers';
import { PATH } from 'paths';
import { CalculatorStep, useCalculatorLoading } from 'contexts/CalculatorLoadingContext';

const Funding: FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useIsDesktop();
    const { setCurrentStep } = useCalculatorLoading();
    const { calculator, setCalculator, iFrameData, setIFrameData } = useContext(MapContext);
    const { user } = useContext(AuthContext);

    const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);
    const [modal, setModal] = useState(false);
    console.log('🚀 ~ FUNDING_IS_IFRAME_DONE', iFrameData);
    const { mutate: setProject } = useSetProjectCalculator();
    const { mutateAsync: createProjectQuotations } = useCreateProjectQuotations();

    const { button, YN_buttons, descriptionTitle, descriptionText, title } = texts.calculator.stepQuestionnaire.funding;
    const { yes, no, link } = YN_buttons;
    const yesButtonBackgroundColor =
        calculator?.funding === ApiResponseYesNo.YN_ANSWER_YES ? theme.palette.primary.goldenYellow : theme.palette.primary.white;
    const noButtonBackgroundColor =
        calculator?.funding === ApiResponseYesNo.YN_ANSWER_NO ? theme.palette.primary.goldenYellow : theme.palette.primary.white;

    const onSetProject = useCallback(
        (calculator: ISavedProjectAddress) => {
            if (calculator?.projectId && calculator?.roofAccess && calculator?.funding) {
                setProject(
                    {
                        itemId: calculator.projectId,
                        roofAccess: calculator.roofAccess,
                        financingOffer: calculator.funding,
                    },
                    {
                        onSuccess: async (data) => {
                            if (user?.user.owner_client) {
                                await createProjectQuotations(data.data.item.item_id, {
                                    onSuccess: () => {
                                        navigate(`/${PATH.CONTRACTOR_OFFERS}`);
                                    },
                                });
                            } else {
                                navigate(link);
                            }
                            setIFrameData(null);
                        },
                        onError: () => {
                            setCurrentStep(null);
                        },
                    }
                );
            }
        },
        [link, navigate, setIFrameData, setProject, user, createProjectQuotations, setCurrentStep]
    );

    const handleChange = (financingOffer: ApiResponseYesNo) => {
        if (calculator?.projectId && calculator?.roofAccess) {
            setCalculator({ ...calculator, funding: financingOffer });
        }
    };

    const handleClick = () => {
        console.log('🚀 FUNDING_CLICK_NEXT', new Date());
        setIsButtonClicked(true);
        setCurrentStep(CalculatorStep.INITIAL);
    };

    useEffect(() => {
        console.log('🚀 FUNDING_IS_IFRAME_DONE_SET_PROJECT_CALCULATOR', { user, calculator, iFrameData, isButtonClicked }, new Date());
        if (!!user && !!calculator?.funding && iFrameData && iFrameData.panels_number >= 5 && isButtonClicked) {
            onSetProject(calculator);
        } else if (iFrameData && iFrameData.panels_number < 5) {
            setModal(true);
            setIsButtonClicked(false);
            setCurrentStep(null);
        }
    }, [calculator, isButtonClicked, iFrameData, onSetProject, user, setCurrentStep]);

    return (
        <Box
            width='100%'
            height='100%'
            sx={{
                overflow: {
                    xs: 'hidden',
                    md: 'visible',
                },
            }}
        >
            {isDesktop ? (
                <ContentSection title={title} onClick={handleClick} buttonText={button.text} disabled={!calculator?.funding}>
                    <Box display='flex' gap='11px' width='100%' margin='0 auto'>
                        <Button.Outlined
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            font={theme.fontFamilies.bold}
                            size={ButtonSize.xLARGE}
                            borderRadius={theme.borderRadius.xLarge}
                            onClick={() => handleChange(ApiResponseYesNo.YN_ANSWER_YES)}
                            backgroundColor={yesButtonBackgroundColor}
                        >
                            {yes}
                        </Button.Outlined>
                        <Button.Outlined
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            font={theme.fontFamilies.bold}
                            size={ButtonSize.xLARGE}
                            borderRadius={theme.borderRadius.xLarge}
                            onClick={() => handleChange(ApiResponseYesNo.YN_ANSWER_NO)}
                            backgroundColor={noButtonBackgroundColor}
                        >
                            {no}
                        </Button.Outlined>
                    </Box>
                    <Box mt={'25px'} height={300} sx={{ direction: 'rtl' }}>
                        <Paper.Calculator>
                            <Description title={descriptionTitle} description={descriptionText} />
                        </Paper.Calculator>
                    </Box>
                </ContentSection>
            ) : (
                <ContentSectionMobileQuestionnaire
                    linkText={descriptionTitle}
                    title={title}
                    descriptionTitle={descriptionTitle}
                    descriptionContent={descriptionText}
                    onClick={handleClick}
                    buttonText={button.text}
                    disabled={!calculator?.funding}
                >
                    <Box display='flex' flexDirection='column' gap='12px' width='80%' m='20px auto'>
                        <Button.Outlined
                            size={ButtonSize.MEDIUM}
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            font={theme.fontFamilies.bold}
                            onClick={() => handleChange(ApiResponseYesNo.YN_ANSWER_YES)}
                            backgroundColor={yesButtonBackgroundColor}
                        >
                            {yes}
                        </Button.Outlined>
                        <Button.Outlined
                            size={ButtonSize.MEDIUM}
                            color={theme.palette.primary.purple}
                            borderColor={theme.palette.primary.sunflowerYellow}
                            font={theme.fontFamilies.bold}
                            onClick={() => handleChange(ApiResponseYesNo.YN_ANSWER_NO)}
                            backgroundColor={noButtonBackgroundColor}
                        >
                            {no}
                        </Button.Outlined>
                    </Box>
                </ContentSectionMobileQuestionnaire>
            )}
            {modal && (
                <InfoModal
                    open={modal}
                    isCloseIcon
                    onClose={() => {
                        setModal(false);
                    }}
                >
                    <Box sx={{ width: '100%', marginTop: '46px', marginBottom: '20px', textAlign: 'center' }}>
                        <Typography.Body2 color={theme.palette.primary.black}>
                            {texts.calculator.stepRoof.type.notEnoughPanelsModal}
                        </Typography.Body2>
                    </Box>
                    <Button.Contained
                        onClick={() => {
                            setModal(false);
                            navigate(`/${PATH.CALCULATOR}/${PATH.ROOF}/${PATH.TYPE}`);
                        }}
                    >{`להגדלת שטח המערכת`}</Button.Contained>
                </InfoModal>
            )}
        </Box>
    );
};

export default Funding;
