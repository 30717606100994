import { useCallback, useEffect, useState } from 'react';

export function useLocalStorage<T>(localStorageKey: string, defaultValue: T, depsToUpdate?: unknown): [T, (value: T) => void] {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const localStorageItem = localStorage.getItem(localStorageKey);
            if (localStorageItem === null) {
                return defaultValue;
            }
            return JSON.parse(localStorageItem) as T;
        } catch (error) {
            console.warn(`Error reading from localStorage for key "${localStorageKey}":`, error);
            return defaultValue;
        }
    });

    const setValue = useCallback(
        (value: T) => {
            try {
                setStoredValue(value);
                localStorage.setItem(localStorageKey, JSON.stringify(value));
            } catch (error) {
                console.error(`Error writing to localStorage for key "${localStorageKey}":`, error);
            }
        },
        [localStorageKey]
    );

    useEffect(() => {
        if (depsToUpdate) {
            try {
                const localStorageItem = localStorage.getItem(localStorageKey);
                if (localStorageItem === null) {
                    setStoredValue(defaultValue);
                } else {
                    setStoredValue(JSON.parse(localStorageItem) as T);
                }
            } catch (error) {
                console.warn(`Error refreshing localStorage value for key "${localStorageKey}":`, error);
                setStoredValue(defaultValue);
            }
        }
    }, [localStorageKey, defaultValue, depsToUpdate]);

    return [storedValue, setValue];
}
