import './App.scss';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Loader } from 'components/atoms';
import SignIn from 'pages/SignIn';
import SteppingLoader from 'components/molecules/SteppingLoader';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { useEffect, useRef } from 'react';
import { TOKEN_STORAGE_KEY, CALCULATOR_STORAGE_KEY } from 'consts/storage.const';
import AuthContext from 'contexts/AuthContext';
import { appBarHeight } from 'theme/sizes';
import GlobalModalsProvider from 'contexts/GlobalModalsProvider';
import AppBar from 'components/organisms/appBar/AppBar';
import { PATH } from 'paths';
import usePullToRefresh from 'hooks/usePullToRefresh.hook';
import queryClient from 'api/apiClient';
import useAuth from 'hooks/useAuth.hook';
import { CalculatorLoadingProvider, useCalculatorLoading } from 'contexts/CalculatorLoadingContext';
import useLoading from 'hooks/useLoading.hook';

const appToken = process.env.REACT_APP_TEMP_PASS;

const AppContent = () => {
    const ref = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();
    const isPricing = pathname.includes(PATH.PRICING);
    const { user, isAgent } = useAuth();
    const { currentStep } = useCalculatorLoading();
    const isLoading = useLoading();

    const [, setCalculator] = useLocalStorage(CALCULATOR_STORAGE_KEY, null);

    // Check if we're in the transition between Funding and Offers/Results
    const isTransitioningFromFunding =
        (!isAgent && pathname.includes('offers')) || pathname.includes(PATH.FUNDING) || pathname.includes(PATH.RESULTS);

    const showSteppingLoader = isTransitioningFromFunding && currentStep !== null && !!user;

    const onTrigger = async () => {
        await queryClient.refetchQueries();
    };

    usePullToRefresh(ref, onTrigger);

    const entries: any = performance.getEntriesByType('navigation');

    useEffect(() => {
        const isReloaded = entries[0].type === 'reload';
        const isNavigate = entries[0].type === 'navigate';
        if (isReloaded || isNavigate) {
            return;
        }
        setCalculator(null);
    }, [setCalculator, entries]);

    return (
        <div ref={ref}>
            <AppBar />
            <Box height={{ md: `calc(100vh - ${appBarHeight}px)`, xs: 'calc(100dvh - 49px)' }} className='container'>
                <Box height={'100%'} position={'relative'}>
                    {!isPricing && isLoading && !showSteppingLoader && <Loader />}
                    {showSteppingLoader && <SteppingLoader />}
                    <Outlet />
                </Box>
            </Box>
        </div>
    );
};

const App = () => {
    const [token, setToken] = useLocalStorage(TOKEN_STORAGE_KEY, null);

    const onPasswordHandler = (password: any) => {
        setToken(password);
    };

    return (token && token === appToken) || !appToken ? (
        <Box>
            <AuthContext>
                <GlobalModalsProvider>
                    <CalculatorLoadingProvider>
                        <AppContent />
                    </CalculatorLoadingProvider>
                </GlobalModalsProvider>
            </AuthContext>
        </Box>
    ) : (
        <SignIn onPasswordHandler={onPasswordHandler} />
    );
};

export default App;
