import React, { FC, useState } from 'react';
import { Box, Paper, Title, Typography, Grid, Button, InfoTooltip } from 'components/atoms';
import { useTheme } from '@mui/material';
import { ButtonSize } from 'components/atoms/Button';
import { ContractTypeModel, IElectricityProductionData } from 'models/common';
import EconomicAndElectricityProduction from 'components/organisms/EconomicAndElectricityProduction';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import texts from 'texts.json';
import { buildOfferDetailsData } from 'data/offersData';
import { IProjectQuotation } from 'models/OffersData';
import { CALCULATOR_STORAGE_KEY } from 'consts/storage.const';
import PanelsLayoutModal from 'components/organisms/PanelsLayoutModal';
import DownloadFullQuotation from 'components/organisms/DownloadFullQuotation';
import { ISavedProjectAddress } from 'models/MapData';

interface IProps {
    data: IProjectQuotation;
    offersModel: ContractTypeModel;
    isVatIncluded: boolean;
    isFullReport: boolean;
    onDownloadFullReport: () => void;
}

const LeftSection: FC<IProps> = ({ data, offersModel, isVatIncluded, onDownloadFullReport, isFullReport = false }) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const [calculator] = useLocalStorage<ISavedProjectAddress | null>(CALCULATOR_STORAGE_KEY, null);

    const [electricityProduction, setElectricityProduction] = useState<IElectricityProductionData | null>(null);
    const [openEconomicModel, setOpenEconomicModel] = useState(false);

    const isPurchaseModel = offersModel === ContractTypeModel.PURCHASE;
    const offerDetails = buildOfferDetailsData(data, isPurchaseModel);
    const { title } = texts.offers.leftSection;

    const buttons = [
        {
            title: 'מודל כלכלי',
            onClick: () => {
                setElectricityProduction(null);
                setOpenEconomicModel(true);
            },
        },
        {
            title: 'נתוני ייצור חשמל',
            onClick: () => {
                setOpenEconomicModel(true);
                setElectricityProduction({
                    electricityProduction: data.electricity_production,
                    annualDcGeneration: data.annual_dc_generation,
                });
            },
        },
    ];

    const cardHeight = isDesktop ? '137px' : '122px';
    const economicModel = offersModel === ContractTypeModel.PURCHASE ? data?.economic_model : data?.entrepreneur_economic_model;

    return (
        <Box textAlign='center' width={{ md: 345, xs: '100%' }} mx={{ md: 'auto' }} px={{ xs: '8px' }}>
            <Box mb={{ md: '30px', xs: '17px' }}>
                {isDesktop && (
                    <>
                        <Title text={title} />
                        <br />
                        <Typography.Body2 font={theme.fontFamilies.xBold} color={theme.palette.primary.purple}>
                            עבור {calculator?.address}
                        </Typography.Body2>
                    </>
                )}
                {isFullReport && (
                    <Box mt={'10px'}>
                        <DownloadFullQuotation onClick={onDownloadFullReport} modelType={offersModel} />
                    </Box>
                )}
            </Box>
            <Grid container rowSpacing={16} columnSpacing={12}>
                {offerDetails.map((item, index) => (
                    <Grid item xs={6} key={index}>
                        <Paper.Regular
                            sx={{
                                minHeight: offerDetails.some((item) => item?.helperText) ? cardHeight : '',
                            }}
                        >
                            <Box display='flex' justifyContent='center' flexDirection='column' gap={4}>
                                <Box>{item.icon}</Box>
                                <Box display='flex' justifyContent='center' flexDirection='column'>
                                    <Typography.Body4>{item.title}</Typography.Body4>
                                    {item.subTitle && <Typography.Body2>{item.subTitle}</Typography.Body2>}
                                </Box>
                                <Box>
                                    <Typography.Body2 font={theme.fontFamilies.regular}>{item.value}</Typography.Body2>
                                </Box>
                            </Box>
                            {item?.helperText && (
                                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} columnGap={'4px'}>
                                    <Typography.Body6 color={item.helperText.color}>{item.helperText.value?.title}</Typography.Body6>
                                    <InfoTooltip firstText={item.helperText.value?.info} fill={item.helperText.color} />
                                </Box>
                            )}
                        </Paper.Regular>
                    </Grid>
                ))}
                {buttons.map((item, index) => (
                    <Grid item xs={6} key={index}>
                        <Button.Contained size={ButtonSize.LARGE} onClick={item.onClick}>
                            <Typography.Body4 color={theme.palette.primary.white}>{item.title}</Typography.Body4>
                        </Button.Contained>
                    </Grid>
                ))}
            </Grid>
            <Box display={'flex'} width={'100%'} mt={'13px'}>
                <PanelsLayoutModal roof_preview={data.roof_preview} />
            </Box>
            {openEconomicModel && (
                <EconomicAndElectricityProduction
                    isVatIncluded={isVatIncluded}
                    modelType={offersModel}
                    electricityProduction={electricityProduction}
                    economicModel={electricityProduction ? null : economicModel}
                    setElectricityProduction={() => {
                        setElectricityProduction(null);
                        setOpenEconomicModel(false);
                    }}
                    setEconomicModel={() => {
                        setOpenEconomicModel(false);
                        setElectricityProduction(null);
                    }}
                    projectId={data?.item_id}
                />
            )}
        </Box>
    );
};

export default LeftSection;
