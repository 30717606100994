import { Box, Dialog, Accordion, AccordionSummary, Typography, AccordionDetails, Chart } from 'components/atoms';
import HeaderWithFullscreen from 'components/molecules/pricing/pricingEntrepreneur/HeaderWithFullscreen';
import EconomicModelTable from 'components/templates/EconomicModelTable';
import EconomicModelTableMobile from 'components/templates/EconomicModelTableMobile';
import { theme } from 'theme';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { Paper, Skeleton } from '@mui/material';
import React, { FC, ReactNode, useState } from 'react';
import texts from 'texts.json';
import { ChartData } from 'chart.js';
import { IDataCategories, IRentEconomicModelObject, PurchaseEconomicModelObject } from 'models/economicModel.model';
import { IChartLabels } from 'components/atoms/chart/Chart';
import { EconomicModel } from 'models/api/pricingEntrepreneur';
import { useLoadingGetProjectCalculator } from 'hooks/useLoading.hook';

interface IProps {
    open: boolean;
    mobileHeader?: ReactNode;
    simulatorDetails?: ReactNode;
    economicModel: any;
    tableTitle: string;
    chartDataSet?: ChartData<'bar', number[]>;
    tableDataCategories: IDataCategories[];
    tableTotalRows?: IRentEconomicModelObject | PurchaseEconomicModelObject | EconomicModel;
    mobileLabels: IDataCategories[];
    headMobileTitles: IDataCategories[];
    chartLabels: IChartLabels;
    dialogBackgroundMobile: string;
    npvRow?: IRentEconomicModelObject | PurchaseEconomicModelObject | EconomicModel;
    fundingComponent?: ReactNode;
    onClose: () => void;
}

const EconomicModelTemplate: FC<IProps> = ({
    open,
    onClose,
    mobileHeader,
    simulatorDetails,
    economicModel,
    tableTitle,
    chartDataSet,
    tableDataCategories,
    mobileLabels,
    headMobileTitles,
    tableTotalRows,
    chartLabels,
    dialogBackgroundMobile,
    npvRow,
    fundingComponent,
}) => {
    const isDesktop = useIsDesktop();
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const isLoading = useLoadingGetProjectCalculator();
    const { table_subtitle, chart } = texts.common.economic_model;

    const graph =
        !isLoading && chartDataSet ? (
            <Chart chartLabels={chartLabels} isFullScreen={isFullScreen} data={chartDataSet} />
        ) : (
            <Skeleton variant='rectangular' height={570} />
        );

    return isDesktop ? (
        <>
            {simulatorDetails && simulatorDetails}
            <Box display='flex' mt={{ md: '30px' }} gap='50px'>
                <Box width='850px'>
                    <HeaderWithFullscreen title={tableTitle} subtitle={table_subtitle} fundingComponent={fundingComponent}>
                        <EconomicModelTable
                            tableTotalRows={tableTotalRows}
                            economicModel={economicModel}
                            tableDataCategories={tableDataCategories}
                            npvRow={npvRow}
                        />
                    </HeaderWithFullscreen>
                </Box>
                <Box width='501px' height='672px'>
                    <HeaderWithFullscreen title={chart.title} subtitle={{ main: chart.subtitle }} setIsFullScreen={setIsFullScreen}>
                        <Paper
                            sx={{
                                height: isFullScreen ? '95%' : '100%',
                                borderRadius: '10px',
                                padding: '50px 20px 50px 20px',
                                marginTop: '20px',
                            }}
                        >
                            {graph}
                        </Paper>
                    </HeaderWithFullscreen>
                </Box>
            </Box>
        </>
    ) : (
        <Dialog open={open} onClose={onClose} backgroundColor={dialogBackgroundMobile} isFullScreen>
            {mobileHeader && mobileHeader}
            <Box display='flex' flexDirection='column' gap={20} p='20px' bgcolor='primary.greyBackground'>
                <Box mx={{ xs: 'auto', md: 0 }}>{simulatorDetails && simulatorDetails}</Box>
                {fundingComponent && fundingComponent}
                <Box>
                    <EconomicModelTableMobile
                        mobileLabels={mobileLabels}
                        headMobileTitles={headMobileTitles}
                        tableTotalRows={tableTotalRows}
                        title={tableTitle}
                        subTitle={table_subtitle.secondary}
                        npvRow={npvRow}
                        economicModel={economicModel}
                    />
                </Box>
                <Accordion expanded={true}>
                    <AccordionSummary>
                        <Typography.Body3 color={theme.palette.primary.purple}>{chart.title}</Typography.Body3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box height={570} p='12px'>
                            {graph}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Dialog>
    );
};

export default EconomicModelTemplate;
