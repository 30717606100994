import React, { Dispatch, FC, FormEvent, SetStateAction } from 'react';
import AuthDialog from 'components/molecules/AuthDialog';
import { Typography, Box, Button, Checkbox } from 'components/atoms';
import { useTheme } from '@mui/material';
import InputsVerificationCode from 'components/molecules/auth/InputsVerificationCode';
import { ButtonSize, ButtonType } from 'components/atoms/Button';
import TextWithLink from 'components/molecules/TextWithLink';
import texts from 'texts.json';

interface IProps {
    showDialog: boolean;
    inputValues: string[];
    setInputValues: Dispatch<SetStateAction<string[]>>;
    keepLoggedIn: boolean;
    setKeepLoggedIn: Dispatch<SetStateAction<boolean>>;
    setIsRegulationsApproved: Dispatch<SetStateAction<boolean>>;
    isRegulationsApproved: boolean;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    handleSendAgain: () => void;
    onClose: () => void;
}

const VerificationCode: FC<IProps> = ({
    showDialog,
    inputValues,
    setInputValues,
    onSubmit,
    keepLoggedIn,
    setKeepLoggedIn,
    handleSendAgain,
    onClose,
    setIsRegulationsApproved,
    isRegulationsApproved,
}) => {
    const DialogData = {
        header: {
            title: 'הזנת קוד אימות',
        },
        footer: {
            element: null,
            text: 'הקוד לא הגיע?',
            onClick: handleSendAgain,
            linkText: 'שלחו שנית',
        },
    };

    const theme = useTheme();
    const { checkBoxes } = texts.signUp;

    return (
        <AuthDialog data={DialogData} open={showDialog} onClose={onClose}>
            <Box
                component='form'
                display='flex'
                flexDirection='column'
                gap='25px'
                textAlign='center'
                width='300px'
                mx='auto'
                onSubmit={onSubmit}
            >
                <Typography.Body2 font={theme.fontFamilies.regular}>שלחנו קוד אימות, האם קיבלת?</Typography.Body2>
                <Typography.Body2 font={theme.fontFamilies.regular}>יש להקליד את הקוד שקיבלתם בהודעה:</Typography.Body2>
                <InputsVerificationCode inputValues={inputValues} setInputValues={setInputValues} />
                <Box display='flex' alignItems='center' gap='10px' justifyContent='center'>
                    <Checkbox onChange={() => setKeepLoggedIn((prev) => !prev)} checked={keepLoggedIn} />
                    <Typography.Body2 font={theme.fontFamilies.regular}>זכרו אותי</Typography.Body2>
                </Box>
                {!isRegulationsApproved && (
                    <Box display='flex' alignItems='center' gap='10px' justifyContent='center'>
                        <Checkbox checked={isRegulationsApproved} onChange={(_, checked) => setIsRegulationsApproved(checked)} rounded />
                        <TextWithLink
                            data={{
                                text: checkBoxes.rules.text,
                                linkText: checkBoxes.rules.linkText,
                                onClick: () => window.open(checkBoxes.rules.link, '_blank', 'noopener noreferrer'),
                            }}
                            colorLink={theme.palette.primary.purple}
                        />
                    </Box>
                )}
                <Button.Contained
                    disabled={inputValues.some((value) => value === '') || !isRegulationsApproved}
                    type={ButtonType.SUBMIT}
                    size={ButtonSize.REGULAR}
                >
                    <Typography.Body2 color={theme.palette.primary.white} font={theme.fontFamilies.regular}>
                        אישור והתחברות
                    </Typography.Body2>
                </Button.Contained>
            </Box>
        </AuthDialog>
    );
};

export default VerificationCode;
