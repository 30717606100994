import EconomicModel from 'components/molecules/economicModel/EconomicModel';
import ElectricityProductionModal from 'components/molecules/economicModel/ElectricityProductionModal';
import EntrepreneurEconomicModel from 'components/molecules/economicModel/EntrepreneurEconomicModel';
import FundingInputs from 'components/molecules/economicModel/FundingInputs';
import { economicModelData, entrepreneurEconomicModelData } from 'data/EconomicModelData';
import { ContractTypeModel, IElectricityProductionData } from 'models/common';
import { IPurchaseEconomicModel, IRentEconomicModel } from 'models/economicModel.model';
import { FC } from 'react';
import { useGetProjectEconomicModelFields } from 'api/queries/calculator';

interface IProps {
    economicModel: IPurchaseEconomicModel | IRentEconomicModel | null;
    electricityProduction: IElectricityProductionData | null;
    modelType: ContractTypeModel;
    isVatIncluded: boolean;
    projectId?: string;
    setEconomicModel: (param: any) => void;
    setElectricityProduction: (param: any) => void;
}
const EconomicAndElectricityProduction: FC<IProps> = ({
    economicModel,
    electricityProduction,
    modelType,
    setEconomicModel,
    setElectricityProduction,
    isVatIncluded,
    projectId,
}) => {
    const { data: economicModelFields } = useGetProjectEconomicModelFields();
    return (
        <>
            {economicModel &&
                (modelType === ContractTypeModel.PURCHASE ? (
                    <EconomicModel
                        economicModel={economicModelData(economicModel as IPurchaseEconomicModel, isVatIncluded, economicModelFields)}
                        onClose={() => setEconomicModel(null)}
                        fundingComponent={<FundingInputs projectId={projectId} />}
                    />
                ) : (
                    <EntrepreneurEconomicModel
                        economicModel={entrepreneurEconomicModelData(economicModel as IRentEconomicModel)}
                        onClose={() => setEconomicModel(null)}
                    />
                ))}
            {electricityProduction && (
                <ElectricityProductionModal data={electricityProduction} onClose={() => setElectricityProduction(null)} />
            )}
        </>
    );
};

export default EconomicAndElectricityProduction;
