import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from 'components/atoms';
import ManagementAccordionSummary from './ManagementAccordionSummary';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { useTheme } from '@mui/material';
import './textStyle.css';
interface IProps {
    disabledForm: boolean;
    openSubscription: boolean;
    setOpenSubscription: (value: boolean) => void;
}

const ManagementAccordionSubscriptions: FC<IProps> = ({ openSubscription, setOpenSubscription }) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    return (
        <Accordion unmountOnExit={false} expanded={openSubscription} onChange={() => setOpenSubscription(!openSubscription)}>
            <AccordionSummary>
                <ManagementAccordionSummary char='9' label='מנויים - נותני שירותים' />
            </AccordionSummary>
            <AccordionDetails>
                <Box width={isDesktop ? '67%' : '100%'} px={isDesktop ? '40px' : '20px'}>
                    <div className='fees-container' dir='rtl'>
                        <div className='fee-section'>
                            <span>
                                <strong>(1) עמלות מכירה</strong> - אורגנוז זכאית לקבל תשלום על כל עסקה שתיסגר כתוצאה מחיבור שבוצע באמצעות
                                אורגנוז ונחתם הסכם בין נותן השירות (קבלן / יזם) לבין המשתמש (הלקוח), העמלות ישולמו ע"י נותן השירות.
                            </span>

                            <ul className='fee-details'>
                                <li>
                                    <u>עמלה בגין עסקת קבלנות (רכישת מתקן אנרגיה ע"י לקוח)</u> - עבור משתמש/לקוח שרכש מתקן אנרגיה מנותן שירות
                                    (קבלן/יזם), העמלה תהיה בסך 3.6% ממחיר המתקן ששילם הלקוח לנותן השירות. העמלה כוללת את כל רכיבי המתקן
                                    לרבות מערכת סולארית, אגירת חשמל, תוספות ומתקני דו שימוש וכו'.
                                </li>

                                <li>
                                    <u>עמלה בגין עסקת יזמות (השכרת גג/שטח/נכס של לקוח)</u> - עבור משתמש/לקוח שהשכיר שטח בנכס שלו לנותן שירות
                                    (יזם/קבלן) לטובת הצבת מערכת אנרגיה. העמלה תהיה בסך 2.6% מסך ההכנסות/חיסכון מחשמל הצפויות מהמתקן למשך כל
                                    תקופת ההסכם בין נותן השירות ללקוח. (נוסחא : צפי ההכנסות/חיסכון של מתקן האנרגיה למשך זמן ההסכם בין
                                    הלקוח/משתמש לבין נותן השירות X 2.6%) העמלה תחושב לפי שיעור היוון של 5.5%.
                                </li>

                                <li>
                                    <u>תנאי תשלום עמלות</u> -
                                    <div>
                                        <u>תשלום 1</u> - בסך 25% מגובה העמלה המלאה בעת חתימת ההסכם בין נותן השירות ללקוח.
                                    </div>
                                    <div>
                                        <u>תשלום 2</u> - בסך 75% מגובה העמלה המלאה בעת אבן דרך 'התקנת ציוד'.
                                    </div>
                                </li>
                                <li>התשלומים יבוצעו עד 7 ימים מאבן הדרך.</li>
                            </ul>
                        </div>

                        <div className='platform-fee'>
                            <span>
                                <strong>(2) דמי מנוי עבור שימוש בפלטפורמה</strong> - בכדי להצטרף כנותן שירות באורגנזה על נותן השירות לשלם
                                דמי מנוי חודשיים בגין שימוש בפלטפורמה.
                            </span>
                        </div>
                    </div>
                    <Box
                        width={'fit-content'}
                        display={'flex'}
                        flexDirection={'column'}
                        rowGap={'16px'}
                        mt={'20px'}
                        justifyContent={'center'}
                    >
                        <Button.Contained
                            onClick={() =>
                                window.open(
                                    'https://www.organuz.com/%D7%A2%D7%9E%D7%95%D7%93-%D7%AA%D7%A9%D7%9C%D7%95%D7%9D-%D7%A0%D7%95%D7%AA%D7%A0%D7%99-%D7%A9%D7%99%D7%A8%D7%95%D7%AA-%D7%9E%D7%A9%D7%95%D7%9C%D7%91',
                                    '_blank'
                                )
                            }
                        >
                            לצפייה בחבילות השונות ורכישת מנוי שנתי
                        </Button.Contained>
                        <Typography.Body4>
                            * יש לשלוח אישור תשלום (אישור העברה בנקאית/אישור תשלום בכרטיס אשראי) בגין רכישת דמי מנוי למייל{' '}
                            <a style={{ color: theme.palette.primary.purple }} href='mailto:service@organuz.com'>
                                service@organuz.com
                            </a>
                        </Typography.Body4>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ManagementAccordionSubscriptions;
