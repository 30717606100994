import { styled, Drawer, useTheme } from '@mui/material';
import { FC, ReactElement, useState } from 'react';
import { Box, Icon, Typography } from 'components/atoms';
import texts from 'texts.json';

const drawerBleeding = 62;

interface IProps {
    address: string | undefined;
    children: ReactElement;
}

const Wrapper = styled('div')({
    position: 'absolute',
    top: -drawerBleeding,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: '0 38px',
    visibility: 'visible',
    backgroundColor: 'inherit',
});

const BaseDrawer = styled(Drawer, {
    shouldForwardProp: (prop: string) => prop !== 'bgColor' && prop !== 'drawerBleeding',
})(({ theme }) => {
    return {
        '& .MuiModal-root': {
            zIndex: drawerBleeding ? 1200 : 1100,
        },
        '& .MuiModal-backdrop': {
            backgroundColor: drawerBleeding ? 'transparent' : '',
        },
        '& .MuiDrawer-paper': {
            height: `calc(100% - ${drawerBleeding}px)`,
            overflow: 'visible',
            backgroundColor: theme.palette.primary.white,
            borderRadius: 'unset',
            touchAction: 'none',
        },
    };
});

const MobileSwipeableDrawer: FC<IProps> = ({ children, address }) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const { title } = texts.offers.swiper;

    return (
        <BaseDrawer
            anchor={'bottom'}
            open={open}
            onClose={toggleDrawer(false)}
            variant={'temporary'}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <>
                <Wrapper onClick={toggleDrawer(!open)}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        width={'100%'}
                        p={'20px'}
                        bgcolor={theme.palette.primary.purple}
                    >
                        <Box display={'flex'} columnGap={'14px'} alignItems={'center'}>
                            <Box display={'flex'} flexDirection={'column'} textAlign={'center'}>
                                <Typography.Body3 color={theme.palette.primary.white} font={theme.fontFamilies.bold}>
                                    {title}
                                </Typography.Body3>
                                {open && address && (
                                    <Typography.Body4 color={theme.palette.primary.white} font={theme.fontFamilies.light}>
                                        {address}
                                    </Typography.Body4>
                                )}
                            </Box>
                            <Icon.DownArrow fill={theme.palette.primary.white} rotate={open ? 0 : 180} />
                        </Box>
                    </Box>
                </Wrapper>
                <Box sx={{ height: '100%', paddingBottom: '20px', overflowY: 'auto', zIndex: 'inherit' }}>{children}</Box>
            </>
        </BaseDrawer>
    );
};

export default MobileSwipeableDrawer;
