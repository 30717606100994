import PaperResultsPurchase from 'components/molecules/PaperResultsPurchase';
import TabsCalculator from 'components/molecules/TabsCalculator';
import { ContractTypeModel, IElectricityProductionData } from 'models/common';
import React, { FC, useState } from 'react';
import EconomicAndElectricityProduction from 'components/organisms/EconomicAndElectricityProduction';
import { buildPurchaseData, buildRentData } from 'data/PaymentModelData';
import texts from 'texts.json';
import { CompanyType } from 'models/api/management';
import { Box, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';

interface IProps {
    purchaseData: any;
    rentData: any;
    isCompany: boolean;
    companyType: CompanyType[] | undefined;
    selectedTab?: ContractTypeModel;
    projectId?: string;
    setSelectedTab: (value: ContractTypeModel) => void;
}

const PaymentModel: FC<IProps> = ({ purchaseData, rentData, isCompany, companyType, selectedTab, setSelectedTab, projectId }) => {
    const theme = useTheme();
    const [openEconomicModel, setOpenEconomicModel] = useState<boolean>(false);
    const [electricityProduction, setElectricityProduction] = useState<IElectricityProductionData | null>(null);

    const {
        paymentModel: { purchase, rent, basedOnMarketPrice },
        subTitle,
    } = texts.results;

    const onSetEconomicModel = () => {
        setElectricityProduction(null);
        setOpenEconomicModel(true);
    };

    const onSetElectricityProduction = (data: IElectricityProductionData) => {
        setElectricityProduction(data);
        setOpenEconomicModel(true);
    };

    const constructorTabData = {
        value: ContractTypeModel.PURCHASE,
        label: purchase.title,
        children: <PaperResultsPurchase data={buildPurchaseData(purchaseData, onSetElectricityProduction, onSetEconomicModel)} />,
        info: purchase.info,
    };

    const entrepreneurTabData = {
        value: ContractTypeModel.RENT,
        label: rent.title,
        children: <PaperResultsPurchase data={buildRentData(rentData, onSetElectricityProduction, onSetEconomicModel)} />,
        info: rent.info,
    };

    let tabsData = [constructorTabData, entrepreneurTabData];

    if (isCompany) {
        const isConstractor = companyType?.some((item) => item === CompanyType.CONSTRACTOR);
        const isEntrepreneur = companyType?.some((item) => item === CompanyType.ENTREPRENEUR);
        if (!isConstractor) {
            tabsData = [entrepreneurTabData];
        }
        if (!isEntrepreneur) {
            tabsData = [constructorTabData];
        }
    }

    const tab = selectedTab || tabsData[0].value;
    const economicModel = tab === ContractTypeModel.PURCHASE ? purchaseData?.economic_model : rentData?.entrepreneur_economic_model;
    return (
        <>
            <Box display={'flex'} justifyContent={'center'}>
                <Typography.Body1 color={theme.palette.primary.purple} font={theme.fontFamilies.bold}>
                    {subTitle}
                </Typography.Body1>
            </Box>
            <TabsCalculator data={tabsData} isResult={true} tab={tab} setTab={setSelectedTab} />
            <Box display={'flex'} mb={'6px'}>
                <Typography.Body4 color={theme.palette.primary.black} font={theme.fontFamilies.regular}>
                    {basedOnMarketPrice}
                </Typography.Body4>
            </Box>
            {openEconomicModel && (
                <EconomicAndElectricityProduction
                    isVatIncluded={purchaseData.vat_included}
                    modelType={tab}
                    electricityProduction={electricityProduction}
                    economicModel={electricityProduction ? null : economicModel}
                    setElectricityProduction={() => {
                        setElectricityProduction(null);
                        setOpenEconomicModel(false);
                    }}
                    setEconomicModel={() => {
                        setOpenEconomicModel(false);
                        setElectricityProduction(null);
                    }}
                    projectId={projectId}
                />
            )}
        </>
    );
};

export default PaymentModel;
