import { useGetRemainingProjects } from 'api/queries/common';
import RemainingSearchesModal from 'components/molecules/RemainingSearchesModal';
import ScreenResolutionModal from 'components/molecules/ScreenResolutionModal';
import ContactUsModal from 'components/organisms/contactUs/ContactUsModal';
import { CALCULATOR_STORAGE_KEY } from 'consts/storage.const';
import { useLocalStorage } from 'hooks/useLocalStorage.hook';
import { PATH } from 'paths';
import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Listener = () => void;

interface IGlobalModalsContext {
    onOpen: () => void;
    setOnApprove: (onApprove: Listener) => void;
    setContactUsModal: (value: boolean) => void;
}

const initialState: IGlobalModalsContext = {
    onOpen: () => {},
    setOnApprove: () => {},
    setContactUsModal: (value: boolean) => {},
};

export const GlobalModalsContext = createContext<IGlobalModalsContext>(initialState);

interface IProps {
    children: ReactNode;
}

const GlobalModalsProvider: FC<IProps> = ({ children }) => {
    const path = useLocation().pathname;
    const navigate = useNavigate();

    const [, setCalculator] = useLocalStorage(CALCULATOR_STORAGE_KEY, null);

    const [open, setOpen] = useState(false);
    const [contactUsModal, setContactUsModal] = useState(false);
    const [onApprove, setOnApprove] = useState<Listener | undefined>(() => {});
    const { data: remainingSearches } = useGetRemainingProjects(open);
    const isUnlimitedProjects = remainingSearches?.data?.item?.remaining_projects === 'UNLIMITED';
    const addressPage = `/${PATH.CALCULATOR}/${PATH.ADDRESS}`;

    const onCancel = useCallback(
        (e: any, reason?: any, isNoMoreSearches?: boolean) => {
            if (reason && reason === 'backdropClick') return;
            if (isNoMoreSearches) {
                setCalculator(null);
                navigate(addressPage);
            }
            setOpen(false);
        },
        [addressPage, navigate, setCalculator]
    );

    const onApproveHandler = useCallback(() => {
        setOpen(false);
        if (!!onApprove) {
            onApprove();
            return;
        }
        setCalculator(null);
        navigate(addressPage);
    }, [addressPage, navigate, onApprove, setCalculator]);

    const onPurchaseNewSearches = useCallback(() => {
        setOpen(false);
        setContactUsModal(true);
    }, []);

    const onOpen = useCallback(() => {
        setOpen(true);
    }, []);

    useEffect(() => {
        setOnApprove(() => undefined);
    }, [path]);

    useEffect(() => {
        if (open && isUnlimitedProjects) {
            onApproveHandler();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUnlimitedProjects, open]);

    const values = useMemo(
        () => ({
            onOpen,
            setOnApprove,
            setContactUsModal,
        }),
        [onOpen, setOnApprove]
    );

    return (
        <GlobalModalsContext.Provider value={values}>
            {remainingSearches?.data?.item && !isUnlimitedProjects && open && (
                <RemainingSearchesModal
                    open={open}
                    onApprove={onApproveHandler}
                    onCancel={onCancel}
                    onPurchaseNewSearches={onPurchaseNewSearches}
                />
            )}
            <ScreenResolutionModal />
            {contactUsModal && <ContactUsModal open={contactUsModal} onClose={() => setContactUsModal(false)} />}
            {children}
        </GlobalModalsContext.Provider>
    );
};

export default GlobalModalsProvider;
