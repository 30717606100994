import { useTheme } from '@mui/material';
import { Box, Button, ButtonSize, Typography } from 'components/atoms';
import { TextFieldSize, TextFieldType } from 'components/atoms/TextField';
import { FC, useState, useEffect } from 'react';
import { useSetProjectEconomicModelFieldsMutation } from 'api/queries/calculator';
import { useForm } from 'react-hook-form';
import ControllerTextField from 'components/organisms/ControllerTextField';

interface IInputField {
    id: string;
    label: string;
    min?: number;
    max?: number;
    isPercentage?: boolean;
    symbol?: string;
}

interface IProps {
    projectId: string | undefined;
}

interface IFormValues {
    loan_rate: string;
    funding_rate: string;
    loan_period: string;
    loan_interest_rate: string;
}

export const initialFundingInputsValues: IFormValues = {
    loan_rate: '80',
    funding_rate: '20',
    loan_period: '10',
    loan_interest_rate: '7',
};

const FundingInputs: FC<IProps> = ({ projectId }) => {
    const theme = useTheme();
    const {
        control,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { isDirty },
        getValues,
    } = useForm<IFormValues>({
        defaultValues: initialFundingInputsValues,
        mode: 'onChange',
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const { mutateAsync: setEconomicModelMutation, data } = useSetProjectEconomicModelFieldsMutation();

    // Watch loan_rate and funding_rate for changes
    const loanRate = watch('loan_rate');
    const fundingRate = watch('funding_rate');

    // Update related field when either loan_rate or funding_rate changes
    useEffect(() => {
        if (loanRate) {
            const currentValues = getValues();
            const newFundingRate = (100 - Number(loanRate)).toString();
            if (currentValues.funding_rate !== newFundingRate) {
                setValue('funding_rate', newFundingRate, { shouldDirty: true });
            }
        }
    }, [loanRate, setValue, getValues]);

    useEffect(() => {
        if (fundingRate) {
            const currentValues = getValues();
            const newLoanRate = (100 - Number(fundingRate)).toString();
            if (currentValues.loan_rate !== newLoanRate) {
                setValue('loan_rate', newLoanRate, { shouldDirty: true });
            }
        }
    }, [fundingRate, setValue, getValues]);

    useEffect(() => {
        if (data) {
            reset({
                loan_rate: data.loan_rate,
                funding_rate: data.funding_rate,
                loan_period: data.loan_period,
                loan_interest_rate: data.loan_interest_rate,
            });
        }
    }, [data, reset]);

    const handleSave = () => {
        if (projectId) {
            if (isEditMode) {
                handleSubmit(async (data) => {
                    const res = await setEconomicModelMutation({
                        item_id: projectId,
                        funding_rate: Number(data.funding_rate),
                        loan_period: Number(data.loan_period),
                        loan_interest_rate: Number(data.loan_interest_rate),
                    });
                    if (res) {
                        setIsEditMode(false);
                        reset(data, { keepDirty: false });
                    }
                })();
            } else {
                setIsEditMode(true);
            }
        }
    };

    const inputFields: IInputField[] = [
        {
            id: 'loan_rate',
            label: 'מימון',
            min: 0,
            max: 100,
            isPercentage: true,
            symbol: '%',
        },
        {
            id: 'funding_rate',
            label: 'הון עצמי',
            min: 0,
            max: 100,
            isPercentage: true,
            symbol: '%',
        },
        {
            id: 'loan_period',
            label: 'תקופת הלוואה',
            min: 0,
            max: 25,
            symbol: 'שנים',
        },
        {
            id: 'loan_interest_rate',
            label: 'ריבית מימון',
            min: 0,
            max: 100,
            isPercentage: true,
            symbol: '%',
        },
    ];

    return (
        <>
            <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ md: 'center', xs: 'flex-start' }}>
                <Box display={'flex'} alignItems={'center'} whiteSpace={'nowrap'} mt={{ md: '22px', xs: 0 }}>
                    <Typography.Body6 whiteSpace={'nowrap'} font={theme.fontFamilies.bold} color={theme.palette.primary.purple}>
                        תנאי המימון המוצגים במודל:
                    </Typography.Body6>
                </Box>
                <Box
                    display={'flex'}
                    width={'100%'}
                    columnGap={{ md: '20px', xs: 0 }}
                    justifyContent={'space-between'}
                    mx={{ md: '20px', xs: 0 }}
                    flexWrap={'wrap'}
                >
                    {inputFields.map((field) => (
                        <Box key={field.id}>
                            <Box>
                                <Typography.Body6 font={theme.fontFamilies.bold}>{field.label}</Typography.Body6>
                                <Box display='flex' alignItems='center' gap={'4px'}>
                                    <Box width={70}>
                                        <ControllerTextField
                                            control={control}
                                            name={field.id}
                                            type={TextFieldType.Number}
                                            size={TextFieldSize.XSmall}
                                            inputProps={{
                                                min: field.min,
                                                max: field.max,
                                                onInput: (e) => {
                                                    const value = Number(e.currentTarget.value);
                                                    if (field.max && value > field.max) {
                                                        e.currentTarget.value = field.max.toString();
                                                    }
                                                    if (field.min && value < field.min) {
                                                        e.currentTarget.value = field.min.toString();
                                                    }
                                                },
                                            }}
                                            disabled={!isEditMode}
                                        />
                                    </Box>
                                    <Typography.Body6>{field.symbol}</Typography.Body6>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box display={'flex'} alignSelf={{ xs: 'flex-start', md: 'flex-end' }}>
                <Button.Contained onClick={handleSave} size={ButtonSize.xSmall} disabled={!isDirty && isEditMode}>
                    {isEditMode ? 'שמירה' : 'עריכה'}
                </Button.Contained>
            </Box>
        </>
    );
};

export default FundingInputs;
